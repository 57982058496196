import { useGetApiApprovalsCheck, useGetUserSettings } from '@sit/client-shared';
import { config } from '@web/config/env';
import { useForceLogoutSITWeb } from '@web/hooks/use-force-logout';
import { useIsAuthenticated } from '@web/redux/hooks';
import DeepLinkingPage from '@web/routes/deep-linking';
import EntriesApprovalsIndex from '@web/routes/entries-approvals._index';
import ExternalAuth from '@web/routes/external-auth';
import RateCards from '@web/routes/rate-cards';
import StaffTimeClocks from '@web/routes/staff-time-clocks';
import TimeApprovalsIndex from '@web/routes/time-approvals._index';
import TimeClocks from '@web/routes/time-clocks';
import TimePreferencesIndex from '@web/routes/time-preferences';
import TimeSourcesIndex from '@web/routes/time-sources';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import useIdentify from '../../analytics/useIdentify';
import PrivateRoute from '../../components/PrivateRoute';
import PublicRoute from '../../components/PublicRoute';
import { entriesApprovalsRoute } from '../EntriesApprovals/helpers/routes';
import ForgotPassword from '../ForgotPassword';
import Homepage from '../Homepage';
import Invite from '../Invite';
import Login from '../Login';
import NotFoundPage from '../NotFoundPage';
import Privacy from '../Privacy';
import { rateCardRoute } from '../RateCards/helpers/routes';
import ResetPassword from '../ResetPassword';
import { timeApprovalsRoute } from '../TimeApprovals/helpers/routes';
import { staffTimeClocksRoute, timeClocksRoute } from '../TimeClocks/helpers/routes';
import { timePreferencesRoute } from '../TimePreferences/helpers/routes';
import Timesheet from '../Timesheet';
import Timesheets from '../Timesheets';

interface RedirectToProps {
  path: string;
}

function redirectTo({ path }: RedirectToProps) {
  return function RedirectTo(..._: unknown[]) {
    const { pathname } = useLocation();
    const staticPathName = pathname.split('/').slice(2).join('/');

    return <Navigate to={`/${path}${staticPathName ? `/${staticPathName}` : ''}`} />;
  };
}

const App = () => {
  const isAuthenticated = useIsAuthenticated();
  useIdentify(isAuthenticated);

  useGetApiApprovalsCheck(
    {},
    {
      enabled: isAuthenticated,
      staleTime: 1000 * 60 * config.VITE_APP_MINUTES_TO_REFRESH_CPTI,
    },
  );

  useGetUserSettings({
    enabled: isAuthenticated,
  });

  useForceLogoutSITWeb();

  return (
    <Routes>
      <Route
        path="/forgot-password"
        element={
          <PublicRoute>
            <ForgotPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/invite/:inviteToken"
        element={
          <PublicRoute>
            <Invite />
          </PublicRoute>
        }
      />
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/reset-password/:resetToken"
        element={
          <PublicRoute>
            <ResetPassword />
          </PublicRoute>
        }
      />

      <Route path="/external-auth" element={<ExternalAuth />} />
      <Route path="/home" element={<Homepage />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/approvals">
        <Route index path="*" element={redirectTo({ path: timeApprovalsRoute.substring(1) })()} />
      </Route>
      <Route path={timeApprovalsRoute}>
        <Route
          index
          path="*"
          element={
            <PrivateRoute>
              <TimeApprovalsIndex />
            </PrivateRoute>
          }
        />
      </Route>
      <Route path={entriesApprovalsRoute}>
        <Route
          index
          path="*"
          element={
            <PrivateRoute>
              <EntriesApprovalsIndex />
            </PrivateRoute>
          }
        />
      </Route>
      <Route path={timePreferencesRoute}>
        <Route
          index
          path="*"
          element={
            <PrivateRoute>
              <TimePreferencesIndex />
            </PrivateRoute>
          }
        />
      </Route>
      <Route path="/data-sources">
        <Route
          index
          path="*"
          element={
            <PrivateRoute>
              <TimeSourcesIndex />
            </PrivateRoute>
          }
        />
      </Route>
      <Route path="/timesheet/:id">
        <Route
          index
          path="*"
          element={
            <PrivateRoute paramsToValidate={{ id: 'uuid' }}>
              <Timesheet />
            </PrivateRoute>
          }
        />
      </Route>
      <Route path="/deep-linking" element={<DeepLinkingPage />} />
      <Route
        path="/timesheets"
        element={
          <PrivateRoute>
            <Timesheets />
          </PrivateRoute>
        }
      />
      <Route path={timeClocksRoute}>
        <Route
          index
          path="*"
          element={
            <PrivateRoute>
              <TimeClocks />
            </PrivateRoute>
          }
        />
      </Route>
      <Route path={rateCardRoute}>
        <Route
          index
          path="*"
          element={
            <PrivateRoute>
              <RateCards />
            </PrivateRoute>
          }
        />
      </Route>
      <Route path={staffTimeClocksRoute}>
        <Route
          index
          path="*"
          element={
            <PrivateRoute>
              <StaffTimeClocks />
            </PrivateRoute>
          }
        />
      </Route>
      <Route
        path="/staff-timesheets"
        element={
          <PrivateRoute>
            <Timesheets />
          </PrivateRoute>
        }
      />
      <Route path="/settings">
        <Route index path="*" element={redirectTo({ path: 'time-preferences' })()} />
      </Route>

      <Route path="/" element={<Navigate to="/timesheets" />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default App;
