import { Trans, useLingui } from '@lingui/react/macro';
import {
  buildOptimisticTimeClock,
  buildOptimisticTimeClockFromDimensionValue,
  PatchApiTimeClocksIdBreaksBreakIdRequestBody,
  PostApiTimeClocksIdBreaksRequestBody,
  PostApiTimeClocksIdDimensionValueRequestBody,
  TimeClock,
  TimeClockBreak,
} from '@sit/client-shared';
import { useQueryClient } from '@tanstack/react-query';
import Button from '@web/components/Shared/Button';
import Textarea from '@web/components/Shared/Textarea/DebouncedTextArea';
import { stringWithFallback } from '@web/helpers/label';
import Box from 'carbon-react/lib/components/box';
import Form from 'carbon-react/lib/components/form';
import Loader from 'carbon-react/lib/components/loader';
import { Tab, Tabs } from 'carbon-react/lib/components/tabs';
import Typography from 'carbon-react/lib/components/typography';
import { FormikErrors, useFormik } from 'formik';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { v4 as randomUUID } from 'uuid';
import ClockField, { OnFieldChangeFn, UpdateClockPayload } from '../ActiveClock/ClockField';
import { ClockDimensionsField, TimeClockDimensionField } from '../ActiveClock/constants';
import { isClockRunning } from '../helpers/activeClockState';
import TimeClockBreaksTable from '../TimeClockBreaksTable';
import DimensionValueField from '../ViewTimeClock/DimensionValueField';
import { useDimensionValueFields } from '../ViewTimeClock/hooks/useDimensionvalueFields';
import { ModalActionKeys } from '../ViewTimeClock/TimeClockModal';
import ActiveControls from './components/ActiveControls';
import DeleteButton from './components/DeleteButton';
import HeadFields from './components/HeadFields';
import StaffFields from './components/StaffFields';
import { fieldDiffs } from './helpers/fieldsDiff';
import { useValidateTimeClock } from './helpers/validation';

const FieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(30%, 1fr));
  grid-template-rows: 1fr;
  gap: ${({ theme }) => theme.spacing * 2}px;
  margin-bottom: ${({ theme }) => theme.spacing}px;
`;

const DescriptionField = styled(Textarea)`
  min-height: ${({ theme }) => theme.spacing * 5 - 2}px !important;
  resize: vertical !important;
  padding: ${({ theme }) => theme.spacing * 1.5 - 2}px ${({ theme }) => theme.spacing * 2}px !important;
`;

const BottomContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 16px;
`;

const TabContent = styled.div`
  margin-top: ${({ theme }) => theme.spacing * 2}px;
`;

export interface TimeClockFormChangePayload {
  value: TimeClock;
  fields: UpdateClockPayload;
  dimensionValues: PostApiTimeClocksIdDimensionValueRequestBody[];
  breaks: {
    modified: [string, PatchApiTimeClocksIdBreaksBreakIdRequestBody][];
    deleted: TimeClockBreak[];
    created: PostApiTimeClocksIdBreaksRequestBody[];
  };
}

export type TimeClockFormSubmitFn = (payload: TimeClockFormChangePayload) => void | Promise<void>;

export interface TimeClockFormProps {
  fields: TimeClockDimensionField[];
  value: TimeClock;
  staffControls?: boolean;
  readonly?: boolean;
  disabledDimensions?: string[];
  dimensionFields: ClockDimensionsField[];
  mode?: 'edit' | 'create';
  saving?: boolean;
  tabProps?: {
    onTabChange: (tab: string) => void;
    activeTab: string;
  };
  onSubmit: TimeClockFormSubmitFn;
  onAction: (action: ModalActionKeys | 'dismiss-modal', value: TimeClock) => void;
}

const DEFAULT_TAB_PROPS = {
  onTabChange: () => undefined,
  activeTab: 'dimensions',
};

function TimeClockForm({
  value,
  fields,
  dimensionFields,
  staffControls = false,
  readonly = false,
  mode = 'edit',
  tabProps = DEFAULT_TAB_PROPS,
  disabledDimensions = [],
  onSubmit,
  onAction,
  saving,
}: TimeClockFormProps) {
  const { t } = useLingui();
  const queryClient = useQueryClient();

  const breaksChangeHistory = useRef<{
    modified: Record<string, PatchApiTimeClocksIdBreaksBreakIdRequestBody>;
    deleted: TimeClockBreak[];
    created: PostApiTimeClocksIdBreaksRequestBody[];
  }>({
    modified: {},
    deleted: [],
    created: [],
  });

  const dimensionsChangeHistory = useRef<Record<string, PostApiTimeClocksIdDimensionValueRequestBody>>({});

  const validateTimeClock = useValidateTimeClock(fields);

  const {
    handleSubmit,
    values: clockValues,
    errors,
    setFieldValue,
    setValues,
  } = useFormik<TimeClock>({
    initialValues: value,
    isInitialValid: false,
    validateOnChange: true,
    validateOnMount: mode === 'edit',
    validate: validateTimeClock,
    onSubmit: async (values) => {
      const changePayload = fieldDiffs(values, value);

      try {
        await onSubmit({
          value: values,
          fields: changePayload,
          dimensionValues: Object.values(dimensionsChangeHistory.current),
          breaks: {
            modified: Object.entries(breaksChangeHistory.current.modified),
            deleted: breaksChangeHistory.current.deleted,
            created: breaksChangeHistory.current.created,
          },
        });
      } catch (error) {
        // Any other validation error will be displayed in the toast.
      }
    },
  });

  useEffect(() => {
    // Reset the change breaks history when the value changes.
    for (const breakItem of value.breaks) {
      if (breaksChangeHistory.current.modified[breakItem.id]) {
        delete breaksChangeHistory.current.modified[breakItem.id];
      }

      breaksChangeHistory.current.created = breaksChangeHistory.current.created.filter((b) => b.id !== breakItem.id);
      breaksChangeHistory.current.deleted = breaksChangeHistory.current.deleted.filter((b) => b.id !== breakItem.id);
    }

    setValues(value);
  }, [value, setValues]);

  const dimensionValueFields = useDimensionValueFields(clockValues, disabledDimensions);

  const onFieldChange: OnFieldChangeFn = async (payload, quiet) => {
    const [optimisticClock, _optimisticDisable] = buildOptimisticTimeClock(queryClient, clockValues, payload);

    if (optimisticClock) {
      setValues(optimisticClock, !quiet);
    }

    return await Promise.resolve();
  };

  const onDimensionValueChange = (payload: PostApiTimeClocksIdDimensionValueRequestBody) => {
    const [optimisticClock, _optimisticDisable] = buildOptimisticTimeClockFromDimensionValue(queryClient, clockValues, payload);

    if (optimisticClock) {
      setValues(optimisticClock, true);
    }

    dimensionsChangeHistory.current[payload.dimensionObjectName] = payload;

    return;
  };

  const onBreakChange = (id: string, body: PatchApiTimeClocksIdBreaksBreakIdRequestBody) => {
    const breakIndex = clockValues.breaks.findIndex((b) => b.id === id);
    if (breakIndex === -1) {
      return;
    }

    const previousValue = clockValues.breaks[breakIndex];

    const newBreakIndex = breaksChangeHistory.current.created.findIndex((b) => b.id === id);
    if (newBreakIndex !== -1) {
      const breakHistory = breaksChangeHistory.current.created[newBreakIndex];
      const createBody = body as PostApiTimeClocksIdBreaksRequestBody;

      if (!breakHistory) {
        return;
      }

      breaksChangeHistory.current.created[newBreakIndex] = { ...breakHistory, ...createBody, id };
    } else {
      const breakHistory = breaksChangeHistory.current.modified[id];
      breaksChangeHistory.current.modified[id] = { ...(breakHistory ? breakHistory : {}), ...body };
    }

    setFieldValue(`breaks[${breakIndex}]`, {
      ...previousValue,
      ...body,
    });

    return;
  };

  const onBreakCreate = (endTime: string | null = new Date().toISOString()) => {
    const newBreak = {
      id: randomUUID(),
      startTime: new Date().toISOString(),
      endTime: endTime === null ? undefined : endTime,
    };

    const newBreaks = [newBreak, ...clockValues.breaks];

    setFieldValue('breaks', newBreaks);
    breaksChangeHistory.current.created.push(newBreak);

    return;
  };

  const onBreakDelete = (id: string) => {
    const breakIndex = clockValues.breaks.findIndex((b) => b.id === id);

    if (breakIndex === -1 || !clockValues.breaks[breakIndex]) {
      return;
    }

    const newBreaks = [...clockValues.breaks];
    breaksChangeHistory.current.deleted.push(clockValues.breaks[breakIndex]);

    newBreaks.splice(breakIndex, 1);

    setFieldValue('breaks', newBreaks);

    return;
  };

  const onFormAction = (action: ModalActionKeys) => {
    onAction(action, clockValues);
  };

  const isReadonly = clockValues.readonly || readonly;
  const displayStaffControls = !clockValues.readonly && staffControls;

  return (
    <Form
      stickyFooter
      height="550px"
      saveButton={
        <>
          <Button
            buttonType={{
              default: 'primary',
              embedded: 'secondary',
            }}
            disabled={saving}
            onClick={() => handleSubmit()}
            ml={1}
          >
            {saving ? <Loader size="small" isInsideButton /> : mode === 'create' ? <Trans>Create</Trans> : <Trans>Save</Trans>}
          </Button>
          {displayStaffControls && mode !== 'create' ? (
            <DeleteButton
              saving={saving}
              onAction={() => {
                onAction('delete', clockValues);
              }}
            />
          ) : null}
        </>
      }
      leftSideButtons={
        <>
          {isClockRunning(clockValues) && <ActiveControls onAction={onFormAction} clock={clockValues} />}
          <Button
            buttonType={{
              default: 'tertiary',
              embedded: 'secondary',
            }}
            onClick={() => onAction('dismiss-modal', clockValues)}
            mr={1}
          >
            <Trans>Close</Trans>
          </Button>
        </>
      }
    >
      {displayStaffControls && (
        <StaffFields
          clock={clockValues}
          enableEmployeeSelect={mode === 'create'}
          enableNullClockout={mode === 'create'}
          onFieldChange={onFieldChange}
          disabled={readonly}
          errors={errors}
        />
      )}
      <FieldsContainer>
        {!displayStaffControls && <HeadFields clock={clockValues} onFieldChange={onFieldChange} readonly={readonly} />}
        {fields
          .filter((t) => !['billable', 'description', 'notes'].includes(t.type))
          .map((field) => (
            <ClockField
              {...field}
              clock={clockValues}
              key={field.type}
              readonly={isReadonly}
              dimensionFields={dimensionFields}
              startDate={clockValues.clockInTime}
              onDimensionValueChange={onDimensionValueChange}
              onFieldChange={onFieldChange}
              error={errors[field.type as keyof TimeClock] as string | undefined}
            />
          ))}
      </FieldsContainer>
      <BottomContainer>
        {readonly ? (
          <>
            <Box>
              <Typography variant="strong">
                <Trans>Description</Trans>
              </Typography>
              <Typography truncate>{stringWithFallback(clockValues.description)}</Typography>
            </Box>
            <Box>
              <Typography variant="strong">
                <Trans>Notes</Trans>
              </Typography>
              <Typography truncate>{stringWithFallback(clockValues.notes)}</Typography>
            </Box>
          </>
        ) : (
          <>
            <DescriptionField
              label={t`Description`}
              defaultValue={clockValues.description ?? ''}
              onValueChangeDebounced={(value) => onFieldChange({ description: value })}
              debounceDelay={300}
            />
            <DescriptionField
              label={t`Notes`}
              defaultValue={clockValues.notes ?? ''}
              onValueChangeDebounced={(value) => onFieldChange({ notes: value })}
              debounceDelay={300}
            />
          </>
        )}
      </BottomContainer>
      <Tabs selectedTabId={tabProps.activeTab} onTabChange={tabProps.onTabChange}>
        <Tab tabId="dimensions" title={t`Dimensions`}>
          <TabContent>
            <FieldsContainer>
              {dimensionValueFields.map((dimensionField) => (
                <DimensionValueField
                  key={dimensionField.objectName}
                  {...dimensionField}
                  onChange={onDimensionValueChange}
                  clock={clockValues}
                  readonly={readonly}
                />
              ))}
            </FieldsContainer>
          </TabContent>
        </Tab>
        {mode === 'edit' && (
          <Tab tabId="breaks" title={t`Breaks`}>
            <TabContent>
              <TimeClockBreaksTable
                editable={displayStaffControls && !readonly}
                fullWidth
                breaks={clockValues.breaks}
                onBreakDelete={onBreakDelete}
                onBreakChange={onBreakChange}
                onBreakCreate={onBreakCreate}
                errors={errors.breaks as FormikErrors<TimeClockBreak>[]}
              />
            </TabContent>
          </Tab>
        )}
      </Tabs>
    </Form>
  );
}

export default TimeClockForm;
