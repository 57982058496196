import { useGetUser } from '@web/api/users/use-get-user';
import { useMemo } from 'react';

export function useDimensionTerminology() {
  const { data: user } = useGetUser();

  const dimensionTerminology = user?.terminology ?? [];

  const dimensionTerminologyMap = useMemo(() => {
    return dimensionTerminology.reduce((acc, { dimensionName, termName }) => {
      acc.set(dimensionName, termName);
      return acc;
    }, new Map<string, string>());
  }, [dimensionTerminology]);

  return (dimensionName: string): string | undefined => {
    return dimensionTerminologyMap.get(dimensionName);
  };
}
