import { modules } from '@web/constants/intacct';
import { useSearchParams } from 'react-router';

function sanitizeMod(mod: string | null): typeof modules.pa | typeof modules.ee {
  return mod === modules.ee ? mod : modules.pa;
}

export function useInitializeIntacctMod() {
  const [urlSearchParams] = useSearchParams();
  const mod = sanitizeMod(urlSearchParams.get('mod'));
  window.sessionStorage.setItem('intacctMod', mod);
}

export function getIntacctMod() {
  return sanitizeMod(window.sessionStorage.getItem('intacctMod'));
}
