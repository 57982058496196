import {
  PostApiTimesheetsError,
  PostApiTimesheetsResponse,
  PostApiTimesheetsVariables,
  usePostTimesheet as usePostApiTimesheet,
} from '@sit/client-shared';
import { UseMutationOptions } from '@tanstack/react-query';
import { track } from '@web/analytics/analyticsClient';
import trackEvents from '@web/analytics/trackEvents';
import { useIncludeEntityData } from '@web/containers/Timesheets/hooks/useIncludeEntityData';
import { useIsStaff } from '@web/containers/Timesheets/hooks/useIsStaff';
import { useAppDispatch } from '@web/redux/store';
import { setSyncStatus } from '@web/redux/timesheets-slice';
import { useNavigate } from 'react-router';
import { usePostTimesheetRow } from '../timesheet-rows/use-post-timesheet-row';

export function usePostTimesheet(
  options?:
    | Omit<UseMutationOptions<PostApiTimesheetsResponse, PostApiTimesheetsError, PostApiTimesheetsVariables, unknown>, 'mutationFn'>
    | undefined,
) {
  const staff = useIsStaff();
  const includePrivate = useIncludeEntityData();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { createTimesheetRow } = usePostTimesheetRow();
  return usePostApiTimesheet({
    ...options,
    onSuccess: (data) => {
      createTimesheetRow(
        {
          employeeId: data.externalEmployeeId,
          timesheetUserId: data.user_id ?? undefined,
        },
        {
          pathParams: {
            id: data.id,
          },
        },
      );
      dispatch(setSyncStatus({ timesheetId: data.id, synced: false }));

      const params = new URLSearchParams();
      if (staff) {
        params.append('staff', 'true');
      }
      if (includePrivate) {
        params.append('includePrivate', 'true');
      }
      let queryString = params.toString();
      if (queryString) {
        queryString = `?${queryString}`;
      }

      navigate(`/timesheet/${data.id}${queryString}`);
      track(trackEvents.TIMESHEET_CREATE);
    },
  });
}
