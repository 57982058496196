import { DimValueWithObjectName, TimeClock } from '@sit/client-shared';

const computeUddName = (dimensionObjectName: string) => {
  switch (dimensionObjectName) {
    case 'DEPARTMENT':
      return 'department';
    case 'LOCATION':
      return 'location';
    case 'COSTTYPE':
      return 'costType';
    default:
      return dimensionObjectName;
  }
};

export function computedUddIds(clock: TimeClock): Record<string, DimValueWithObjectName> {
  const { dimensionsValues = [] } = clock;

  const dimensions: Record<string, DimValueWithObjectName> = {};

  for (const dimension of dimensionsValues) {
    dimensions[computeUddName(dimension.dimensionObjectName)] = dimension;
  }

  return dimensions;
}
