import { t } from '@lingui/core/macro';
import { PushNotification, handleResponseErrors } from '@sit/client-shared';
import toastr from '../../helpers/toastr';
import { NotificationTopic, NotificationType } from './NotificationHandlerService';

type MessageMap = Partial<Record<NotificationType, string>>;

const displayToast = (notification: PushNotification, messageMap: MessageMap, options = {}): void => {
  const message = messageMap[notification.type];
  const toastId = notification.id || message;

  const toastFn = (msg: string): void => {
    switch (notification.type) {
      case 'SUCCESS':
        toastr.success(msg, { ...options, toastId });
        break;
      case 'WARNING':
        toastr.warning(msg, { ...options, toastId });
        break;
      case 'ERROR':
        toastr.error(msg, { ...options, toastId });
        break;
      default:
        toastr.info(msg, { ...options, toastId });
    }
  };

  if (message) {
    toastFn(message);
  }
};

const toApprovalToastMessages = ({ actionType = 'UNKNOWN', reason }: { actionType: string | undefined; reason?: string }) => {
  switch (actionType) {
    case 'APPROVE': {
      return {
        SUCCESS: t`Successfully approved`,
        ERROR: reason ? t`Failed to approve: ${reason}` : t`Failed to approve`,
      };
    }
    case 'DECLINE': {
      return {
        SUCCESS: t`Successfully declined`,
        ERROR: reason ? t`Failed to decline: ${reason}` : t`Failed to decline`,
      };
    }

    default: {
      return {
        SUCCESS: t`Successfully processed`,
        ERROR: reason ? t`Failed to process: ${reason}` : t`Failed to process`,
      };
    }
  }
};

export const toastNotification = (notification: PushNotification): unknown => {
  switch (notification.topic as NotificationTopic) {
    case NotificationTopic.SAVE_TIMESHEET:
      return displayToast(
        notification,
        {
          SUCCESS: t`Your timesheet has been successfully synced to Intacct`,
          ERROR: t`Your timesheet has failed to sync to Intacct`,
        },
        {
          toastId: notification.data?.timesheetId,
        },
      );
    case NotificationTopic.SUBMIT_TIMESHEET:
      return displayToast(
        notification,
        {
          SUCCESS: t`Your timesheet has been successfully submitted`,
          ERROR: t`Your timesheet failed to submit`,
        },
        {
          toastId: notification.data?.timesheetId,
        },
      );
    case NotificationTopic.APPROVALS: {
      const reason = notification.type === 'ERROR' ? handleResponseErrors(notification.data?.reason) : '';
      const toastId = notification.type === 'ERROR' ? reason : notification.id;

      return displayToast(
        notification,
        toApprovalToastMessages({
          actionType: notification.data?.action,
          reason,
        }),
        {
          toastId,
        },
      );
    }
    default:
      return null;
  }
};
