import { useLingui } from '@lingui/react/macro';
import { ClockDimensionsField } from '../constants';
import { useDimensionTerminology } from '@web/hooks/useDimensionTerminology';

export const useFieldLabel = (field: ClockDimensionsField): string => {
  const { t } = useLingui();
  const getTerm = useDimensionTerminology();

  const labels = {
    billable: t`Billable`,
    client: getTerm('CUSTOMER') ?? t`Customer`,
    costType: getTerm('COSTTYPE') ?? t`Cost type`,
    department: getTerm('DEPARTMENT') ?? t`Department`,
    description: t`Description`,
    item: getTerm('ITEM') ?? t`Item`,
    location: getTerm('LOCATION') ?? t`Location`,
    notes: t`Notes`,
    project: getTerm('PROJECT') ?? t`Project`,
    task: getTerm('TASK') ?? t`Task`,
    timeType: t`Time type`,
  } as Record<ClockDimensionsField, string>;

  return labels[field];
};
