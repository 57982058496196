import { getUserQueryData, toDateFnsFormat, type UserType } from '@sit/client-shared';
import { toAppLocale } from '@sit/core';
import { useGetUser } from '@web/api/users/use-get-user';
import { queryClient } from '@web/query-client';
import { useIsAuthenticated } from '@web/redux/hooks';

const enableLocale = import.meta.env.VITE_APP_ENABLE_LOCALE === 'true';

function selectLocale(data: UserType) {
  return {
    locale: data.locale,
    dateFormat: data.dateFormat ? toDateFnsFormat(data.dateFormat) : undefined,
  };
}

export function useUserLocale() {
  const isAuthenticated = useIsAuthenticated();
  const userLocale = useGetUser({ enabled: isAuthenticated && enableLocale, select: selectLocale }).data;
  const locale = toAppLocale(userLocale?.locale);
  const dateFormat = userLocale?.dateFormat;
  return { locale, dateFormat };
}

/**
 * Only use this outside of React components.
 */
export function getLocale() {
  return toAppLocale(getUserQueryData(queryClient)?.locale);
}
