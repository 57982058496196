import shade from 'carbon-react/lib/style/utils/shade';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    font-family: ${({ theme }) => (theme.name === 'SIT-Embeded' ? 'Helvetica Neue, sans-serif' : '"Sage UI", sans-serif')};
  }
  a {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 700;
    text-decoration: none;
    &:hover {
      color: ${({ theme }) => shade(theme.colors.primary)(20)};
      text-decoration: underline;
    }
  }

  [data-element="flat-table-cell"]::before, [data-element="flat-table-row-header"]::before, [data-element="flat-table-checkbox-cell"]::before {
    display: none !important;
  }

  [data-element="flat-table-row"]::after {
    z-index: 99999;
  }
`;

export default GlobalStyle;
