import moment, { Moment } from 'moment';
import { FIRST_OF_MONTH, MID_OF_MONTH } from '../constants/date';

export const timesheetStartsFirstDayOfMonth = (value: string) => {
  return moment(value).date() === FIRST_OF_MONTH;
};

export const timesheetStartsMidOfMonth = (value: string) => {
  return moment(value).date() === MID_OF_MONTH;
};

export const isWeekend = (date: Moment) => {
  const weekends = [0, 6];

  return weekends.includes(date.day());
};

export function toUtcDate(date: Date) {
  return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
}

export function getDayName(dayNumberOrDate: number | Date, locale = 'en-US') {
  const date = typeof dayNumberOrDate === 'number' ? new Date(1970, 0, dayNumberOrDate + 4) : new Date(dayNumberOrDate);
  return new Intl.DateTimeFormat(locale, { weekday: 'long' }).format(date);
}
