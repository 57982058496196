import { useLingui } from '@lingui/react/macro';
import { formatIntacctDate, useUserDateFormat } from '@sit/client-shared';
import CarbonDateInput, { DateInputProps as CarbonDateInputProps, DateChangeEvent } from 'carbon-react/lib/components/date';
import { getSeparator } from 'carbon-react/lib/components/date/__internal__/utils';
import { isValid, parseISO } from 'date-fns';
import { useState } from 'react';

export interface DateInputProps extends Omit<CarbonDateInputProps, 'onChange'> {
  onChange?: CarbonDateInputProps['onChange'];
}

function isInvalidDateFormat(value: string, dateFormat: string) {
  return value.length !== dateFormat.length || getSeparator(value) !== getSeparator(dateFormat);
}

export default function DateInput({ onChange, value, error, ...props }: DateInputProps) {
  const { t } = useLingui();

  const dateFormat = useUserDateFormat();
  const [invalidValue, setInvalidValue] = useState<string | null>(null);

  const handleOnChange = (ev: DateChangeEvent) => {
    const { formattedValue } = ev.target.value;
    const isInvalid = isInvalidDateFormat(formattedValue, dateFormat);

    setInvalidValue(isInvalid ? formattedValue : null);

    onChange?.(ev);
  };

  const effectiveValue = invalidValue ?? value;
  const isDateValid = invalidValue === null || (!isInvalidDateFormat(effectiveValue, dateFormat) && isValid(parseISO(value)));

  const formattedValue =
    effectiveValue.length >= dateFormat.length && isValid(parseISO(effectiveValue))
      ? formatIntacctDate(effectiveValue, dateFormat)
      : effectiveValue;
  const errorMessage = effectiveValue !== '' && !isDateValid ? t`Invalid date` : error;

  return (
    <CarbonDateInput
      {...props}
      onChange={handleOnChange}
      value={effectiveValue}
      error={errorMessage}
      // @ts-ignore -- this field is not allowed and should be reviewed, but it might be a carbon-react issue
      formattedValue={formattedValue}
    />
  );
}
