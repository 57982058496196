import { useMemo } from 'react';
import { useLocation } from 'react-router';

/**
 * @see https://v5.reactrouter.com/web/example/query-parameters
 */
export function useLocationQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}
