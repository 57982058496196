import { TimeClock } from '@sit/client-shared';
import { formatDimensionValue } from '@sit/core';
import { useShowIntacctId } from '@web/api/show-intacct-id';
import { stringWithFallback } from '@web/helpers/label';
import Box from 'carbon-react/lib/components/box';
import Typography from 'carbon-react/lib/components/typography';
import { ClockDimensionsField, TimeClockEditableFieldsKeys } from '../../ActiveClock/constants';
import { useFieldLabel } from '../../ActiveClock/hooks/useFieldLabels';
import { computedUddIds } from '../helpers/udd';

interface ReadOnlyClockFieldProps {
  clock: TimeClock;
  type: ClockDimensionsField;
}

function computeFieldValue(clock: TimeClock, type: ClockDimensionsField, showIntacctId: boolean): string | boolean | null {
  if (['billable', 'description', 'notes'].includes(type)) {
    return clock[type as 'billable' | 'description' | 'notes'];
  }

  if (['location', 'department'].includes(type)) {
    const dimensionData = computedUddIds(clock)[type as 'location' | 'department'];

    return dimensionData === undefined
      ? stringWithFallback()
      : formatDimensionValue(showIntacctId, dimensionData.externalId, dimensionData.name);
  }

  const fieldData = clock[type as TimeClockEditableFieldsKeys];
  const hideIntacctId = !fieldData || !('externalId' in fieldData) || !showIntacctId || fieldData.externalId === null;

  return hideIntacctId ? stringWithFallback(fieldData?.name) : formatDimensionValue(showIntacctId, fieldData.externalId, fieldData.name);
}

export const ReadOnlyClockField = ({ clock, type }: ReadOnlyClockFieldProps) => {
  const showIntacctId = useShowIntacctId();
  const label = useFieldLabel(type);
  const value = computeFieldValue(clock, type, showIntacctId);

  return (
    <Box>
      <Typography variant="strong">{label}</Typography>
      <Typography truncate>{value}</Typography>
    </Box>
  );
};
