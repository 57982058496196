import { useLingui } from '@lingui/react/macro';
import { CompanyType, TimesheetType } from '@sit/client-shared';
import { DataItem } from '@web/components/Shared/TableHead';
import { DimensionsState } from '../Dimensions/dimension-state';
import { useDimensionTerminology } from '@web/hooks/useDimensionTerminology';

type CompanyDimensionFlags = Pick<
  CompanyType,
  | 'showDepartmentLocation'
  | 'showTimeType'
  | 'showItem'
  | 'showTask'
  | 'showProject'
  | 'showCustom'
  | 'showBillable'
  | 'showCostType'
  | 'showEmployeePosition'
  | 'showLaborClass'
  | 'showLaborShift'
  | 'showLaborUnion'
>;

type MappedDimensionFlags = keyof CompanyDimensionFlags;

export type MappedDimensionValues = keyof DimensionsState;

const getTypeVisibilityFromTimesheet = (
  type: MappedDimensionFlags,
  timesheet: TimesheetType | undefined,
  company: CompanyDimensionFlags | null,
): boolean => {
  switch (type) {
    case 'showDepartmentLocation':
    case 'showTimeType':
    case 'showItem':
    case 'showTask':
    case 'showProject':
    case 'showCustom':
    case 'showCostType':
    case 'showEmployeePosition':
    case 'showLaborClass':
    case 'showLaborShift':
    case 'showLaborUnion':
    case 'showBillable':
      return timesheet?.[type] ?? company?.[type] ?? false;
    default:
      return false;
  }
};

const mappedDimensions = [
  ['showDepartmentLocation' satisfies MappedDimensionFlags, ['departmentId', 'locationId'] satisfies MappedDimensionValues[]],
  ['showTimeType' satisfies MappedDimensionFlags, ['timeTypeId'] satisfies MappedDimensionValues[]],
  ['showItem' satisfies MappedDimensionFlags, ['itemId'] satisfies MappedDimensionValues[]],
  ['showTask' satisfies MappedDimensionFlags, ['taskId'] satisfies MappedDimensionValues[]],
  ['showProject' satisfies MappedDimensionFlags, ['projectId'] satisfies MappedDimensionValues[]],
  ['showCustom' satisfies MappedDimensionFlags, ['clientId'] satisfies MappedDimensionValues[]],
  ['showCostType' satisfies MappedDimensionFlags, ['costTypeId'] satisfies MappedDimensionValues[]],
  ['showBillable' satisfies MappedDimensionFlags, ['billable'] satisfies MappedDimensionValues[]],
  ['showEmployeePosition' satisfies MappedDimensionFlags, ['employeePositionId'] satisfies MappedDimensionValues[]],
  ['showLaborClass' satisfies MappedDimensionFlags, ['laborClassId'] satisfies MappedDimensionValues[]],
  ['showLaborShift' satisfies MappedDimensionFlags, ['laborShiftId'] satisfies MappedDimensionValues[]],
  ['showLaborUnion' satisfies MappedDimensionFlags, ['laborUnionId'] satisfies MappedDimensionValues[]],
] as const;

export const getAvailableDimension = (company: CompanyDimensionFlags | undefined, timesheet?: TimesheetType): MappedDimensionValues[] => {
  if (!company) {
    return [];
  }
  const values = mappedDimensions
    .filter(([key]) => getTypeVisibilityFromTimesheet(key, timesheet, company))
    .flatMap(([, values]) => values);
  return values;
};

interface DimensionTitleMap {
  title: string;
  order: number;
}

const useLocalizedDimensionTitles = (): Record<MappedDimensionValues, DimensionTitleMap> => {
  const { t } = useLingui();
  const getTerm = useDimensionTerminology();

  return {
    clientId: {
      title: getTerm('CUSTOMER') ?? t`Customer`,
      order: 0,
    },
    projectId: {
      title: getTerm('PROJECT') ?? t`Project`,
      order: 1,
    },
    taskId: {
      title: getTerm('TASK') ?? t`Task`,
      order: 2,
    },
    itemId: {
      title: getTerm('ITEM') ?? t`Item`,
      order: 3,
    },
    costTypeId: {
      title: getTerm('COSTTYPE') ?? t`Cost type`,
      order: 4,
    },
    timeTypeId: {
      title: t`Time type`,
      order: 5,
    },
    employeePositionId: {
      title: getTerm('EMPLOYEEPOSITION') ?? t`Employee position`,
      order: 6,
    },
    laborClassId: {
      title: getTerm('LABORCLASS') ?? t`Labor class`,
      order: 7,
    },
    laborShiftId: {
      title: getTerm('LABORSHIFT') ?? t`Labor shift`,
      order: 8,
    },
    laborUnionId: {
      title: getTerm('LABORUNION') ?? t`Labor union`,
      order: 9,
    },
    billable: {
      title: t`Billable`,
      order: 10,
    },
    departmentId: {
      title: getTerm('DEPARTMENT') ?? t`Department`,
      order: 11,
    },
    locationId: {
      title: getTerm('LOCATION') ?? t`Location`,
      order: 12,
    },
  };
};

const useSortHeader = () => {
  const titles = useLocalizedDimensionTitles();

  return (a: MappedDimensionValues, b: MappedDimensionValues): number => {
    return titles[a].order - titles[b].order;
  };
};

export interface HeadersData {
  title: string;
  key: MappedDimensionValues;
  order: number;
  sortable?: boolean;
}

function isSortable(key: keyof DimensionsState) {
  switch (key) {
    case 'clientId':
    case 'projectId':
    case 'taskId':
    case 'locationId':
    case 'departmentId':
    case 'itemId':
    case 'timeTypeId':
      return true;
    default:
      return false;
  }
}

export const useGetHeadersData = (company: CompanyType | undefined, timesheet?: TimesheetType): HeadersData[] => {
  const titles = useLocalizedDimensionTitles();
  const sortHeader = useSortHeader();

  return getAvailableDimension(company, timesheet)
    .sort(sortHeader)
    .map((key) => ({
      title: titles[key].title,
      key,
      order: titles[key].order,
      sortable: isSortable(key),
    }));
};

export const useGetTimeApprovalsHeadersData = (
  company: CompanyType | undefined,
  timesheet?: TimesheetType,
): DataItem<keyof DimensionsState>[] => {
  const titles = useLocalizedDimensionTitles();

  return getAvailableDimension(company, timesheet).map((key) => ({
    id: key,
    label: titles[key].title,
    name: key,
    width: 30,
    ...(key === 'billable' ? {} : { type: 'dimension' }),
  }));
};
