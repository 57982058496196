import { setCompanyForSessionStorage } from '@sit/client-shared';
import { formatIntacctEntityName } from '@web/helpers/entity';
import { useEffect } from 'react';
import { useQueryParams } from '../helpers/routing';
import { getCompanyFromWebSessionStorage } from '../helpers/session';
import { useSelectCompanyInfo } from './use-select-company-info';

export function useCurrentEntity() {
  // Priority:
  // 1. URL param (company.name (Intacct entity ID), USA-100, for cleaner URLs)
  // 2. Current entity from session (company.id)
  const entityParam = useQueryParams().get('entity'); // in the format of USA-100
  const { entities, defaultEntityId } = useSelectCompanyInfo();
  // If entity param is "Top level", use it.
  // If it's provided but not "Top level", use it.
  // Else, undefined.
  const entityParamId =
    entityParam === 'Top level'
      ? entities?.find((e) => e.intacctEntityId == null)?.id
      : entityParam
        ? entities?.find((e) => formatIntacctEntityName(e) === entityParam)?.id
        : undefined; // UUID
  const sessionEntity = getCompanyFromWebSessionStorage()?.id; // UUID
  const entityId = entityParamId || sessionEntity;
  const currentEntity = entityId ? entities?.find((c) => c.id === entityId) : entities?.find((c) => c.id === defaultEntityId);
  useEffect(() => {
    if (entityParamId && currentEntity?.id === entityParamId && sessionEntity !== entityParamId) {
      // Update session storage since the URL is the source of truth
      setCompanyForSessionStorage(currentEntity.id);
    }
  }, [entityParamId, sessionEntity, currentEntity]);
  return currentEntity;
}
