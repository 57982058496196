import Tooltip from 'rc-tooltip';
import { JSXElementConstructor, ReactElement, cloneElement, forwardRef, useRef } from 'react';

export interface CustomTooltipProps {
  overlay?: React.ReactNode;
  placement?: 'top' | 'topLeft' | 'topRight' | 'bottom' | 'bottomLeft' | 'bottomRight';
  alwaysShow?: boolean;
  children: ReactElement<any, string | JSXElementConstructor<any>>;
}

function CustomTooltip({ overlay, placement, alwaysShow, children }: CustomTooltipProps) {
  const childRef = useRef<any>(undefined);

  const _place = () => {
    if (placement) return placement;
    if (typeof overlay === 'string' && overlay.length > 30) {
      return 'topLeft';
    }
    return 'top';
  };

  const ChildComponentWithRef = forwardRef((props, ref) =>
    cloneElement(children, {
      ...props,
      ref,
    }),
  );
  ChildComponentWithRef.displayName = 'ChildComponentWithRef';
  const parentEl = childRef.current?.parentElement || {};
  const shouldShowTooltip = parentEl?.offsetWidth < parentEl?.scrollWidth;
  return (
    <Tooltip
      overlay={overlay}
      overlayClassName={`rc-tooltip-classname fs-exclude ${(!overlay || (!alwaysShow && !shouldShowTooltip)) && 'hide'}`}
      placement={_place()}
    >
      <ChildComponentWithRef ref={childRef} />
    </Tooltip>
  );
}

export default CustomTooltip;
