import { Trans, useLingui } from '@lingui/react/macro';
import { TimeClock } from '@sit/client-shared';
import { SelectEmployee } from '@web/components/Fields/Select/Employee';
import DateTimeInput from '@web/components/Shared/DateTimeInput';
import { stringWithFallback } from '@web/helpers/label';
import Box from 'carbon-react/lib/components/box';
import { Checkbox, CheckboxGroup } from 'carbon-react/lib/components/checkbox';
import Icon from 'carbon-react/lib/components/icon';
import IconButton from 'carbon-react/lib/components/icon-button';
import Typography from 'carbon-react/lib/components/typography';
import { FormikErrors } from 'formik';
import styled from 'styled-components';
import { OnFieldChangeFn } from '../../ActiveClock/ClockField';
import { getTimeClockDate } from '../../ViewTimeClock/hooks/useTimeClockDate';

const StaffFieldsRoot = styled.div`
  display: grid;
  grid-template: 
    [info] "employee billable" [info] 
    [staff-time-controls-start] "clock-in clock-out" [staff-time-controls-end]
    / 1fr 1fr;
  margin-bottom: ${({ theme }) => theme.spacing * 5}px;
  gap: ${({ theme }) => theme.spacing * 2}px;
`;

const ClockInContainer = styled.div`
  grid-area: clock-in;
`;

const ClockOutContainer = styled.div`
  grid-area: clock-out;
`;

const EmployeeContainer = styled.div`
  grid-area: employee;
`;

const BillableContainer = styled.div`
  grid-area: billable;
`;

const NullableLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface NullableDateTimeInput {
  value: Date | null;
  onChange: (value: Date | null) => void;
  disabled?: boolean;
  error?: boolean | string;
}

function NullableDateTimeInput({ value, error, disabled = false, onChange }: NullableDateTimeInput) {
  const { t } = useLingui();

  const handleChange = () => {
    onChange(value ? null : new Date());
  };

  const tooltipMessage = value ? t`Delete clock out time` : t`Set clock out time`;
  const iconType = value ? 'minus' : 'plus';

  return (
    <DateTimeInput
      value={value ?? new Date()}
      onChange={onChange}
      disabled={disabled || value === null}
      error={error}
      label={
        <NullableLabel>
          <Typography variant="strong">
            <Trans>Clock out</Trans>
          </Typography>

          <IconButton onClick={() => handleChange()}>
            <Icon type={iconType} tooltipMessage={tooltipMessage} />
          </IconButton>
        </NullableLabel>
      }
    />
  );
}

export interface StaffFieldsProps {
  clock: TimeClock;
  disabled: boolean;
  onFieldChange: OnFieldChangeFn;
  errors?: FormikErrors<TimeClock>;
  enableNullClockout?: boolean;
  enableEmployeeSelect?: boolean;
}

function StaffFields({
  clock,
  disabled,
  onFieldChange,
  errors = {},
  enableNullClockout = false,
  enableEmployeeSelect = false,
}: StaffFieldsProps) {
  const { t } = useLingui();

  const { clockIn, clockOut } = getTimeClockDate(clock, onFieldChange);

  return (
    <StaffFieldsRoot>
      <ClockInContainer>
        <DateTimeInput
          value={clockIn}
          disabled={disabled}
          onChange={(clockInTime) =>
            onFieldChange({
              clockInTime: clockInTime.toISOString(),
            })
          }
          error={errors.clockInTime}
          label={t`Clock in`}
        />
      </ClockInContainer>
      <ClockOutContainer>
        {enableNullClockout && (
          <NullableDateTimeInput
            value={clockOut ?? null}
            disabled={disabled}
            error={errors.clockOutTime}
            onChange={(clockOutTime) =>
              onFieldChange({
                clockOutTime: clockOutTime?.toISOString() ?? null,
              })
            }
          />
        )}
        {!enableNullClockout && clockOut && (
          <DateTimeInput
            value={clockOut}
            disabled={disabled}
            error={errors.clockOutTime}
            onChange={(clockOutTime) =>
              onFieldChange({
                clockOutTime: clockOutTime.toISOString(),
              })
            }
            label={t`Clock out`}
          />
        )}
      </ClockOutContainer>
      <EmployeeContainer>
        {!enableEmployeeSelect ? (
          <Box>
            <Typography variant="strong">
              <Trans>Employee</Trans>
            </Typography>
            <Typography mt={1} truncate>
              {stringWithFallback(clock.employee.name)}
            </Typography>
          </Box>
        ) : (
          <SelectEmployee
            label={t`Employee`}
            required
            value={clock.employee?.id}
            companyId={clock.companyId}
            disabled={disabled}
            firstAsDefault
            onChange={(employee, quiet) =>
              onFieldChange(
                {
                  employee,
                },
                quiet,
              )
            }
          />
        )}
      </EmployeeContainer>
      <BillableContainer>
        <CheckboxGroup legend={t`Billable`}>
          <Checkbox checked={clock.billable} disabled={disabled} onChange={({ target }) => onFieldChange({ billable: target.checked })} />
        </CheckboxGroup>
      </BillableContainer>
    </StaffFieldsRoot>
  );
}

export default StaffFields;
