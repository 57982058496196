import { Tabs, TabsProps } from 'carbon-react/lib/components/tabs';
import { ReactNode, useCallback, useState } from 'react';
import { useNavigate } from 'react-router';

type CarbonTabsWithReactRouterProps = {
  children: ReactNode;
  initialTab?: string;
} & TabsProps;

const CarbonTabsWithReactRouter = ({
  children,
  initialTab = '',
  renderHiddenTabs = false,
  onTabChange,
  ...rest
}: CarbonTabsWithReactRouterProps) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(initialTab);
  const handleTabChange = useCallback(
    (tabId: string) => {
      navigate(`../${tabId}`);
      setActiveTab(tabId);
    },
    [navigate],
  );

  const pickHandleTabChange = typeof onTabChange === 'function' ? onTabChange : handleTabChange;

  return (
    <Tabs onTabChange={pickHandleTabChange} selectedTabId={activeTab} renderHiddenTabs={renderHiddenTabs} {...rest}>
      {children}
    </Tabs>
  );
};

export default CarbonTabsWithReactRouter;
