import { useLingui } from '@lingui/react/macro';
import {
  PostApiTimesheetsByUuidSaveAsyncError,
  PostApiTimesheetsByUuidSaveAsyncResponse,
  PostApiTimesheetsByUuidSaveAsyncVariables,
  usePostTimesheetSaveAsync as useSaveAsync,
} from '@sit/client-shared';
import { UseMutationOptions } from '@tanstack/react-query';
import toastr from '@web/helpers/toastr';

export function usePostTimesheetSaveAsync(
  options?:
    | Omit<
        UseMutationOptions<
          PostApiTimesheetsByUuidSaveAsyncResponse,
          PostApiTimesheetsByUuidSaveAsyncError,
          PostApiTimesheetsByUuidSaveAsyncVariables,
          unknown
        >,
        'mutationFn'
      >
    | undefined,
) {
  const { t } = useLingui();
  return useSaveAsync({
    ...options,
    onSuccess: async (data, variables, context) => {
      toastr.info(t`Syncing your timesheet, we'll notify you when we finish.`, { toastId: variables.pathParams.id });
      await options?.onSuccess?.(data, variables, context);
    },
  });
}
