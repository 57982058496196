import { useLingui } from '@lingui/react/macro';
import PopoverContainer from 'carbon-react/lib/components/popover-container';
import NotificationButton from './NotificationButton';
import NotificationsPanel from './NotificationsPanel';
import useNotifications from './hooks/useNotifications';
import { useSetNotificationsAsRead } from './hooks/useSetNotificationsAsRead';

const Notifications = (): JSX.Element => {
  const { t } = useLingui();
  const notifications = useNotifications();
  const setAsRead = useSetNotificationsAsRead();

  return (
    <PopoverContainer onOpen={setAsRead} title={t`Notifications`} position="left" renderOpenComponent={NotificationButton}>
      <NotificationsPanel notifications={notifications} />
    </PopoverContainer>
  );
};

export default Notifications;
