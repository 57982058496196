import { entriesApprovalsRoute } from '@web/containers/EntriesApprovals/helpers/routes';
import { timeApprovalsRoute } from '@web/containers/TimeApprovals/helpers/routes';
import { useMatch, useNavigate } from 'react-router';

const useApprovalTabs = (path: string) => {
  const navigate = useNavigate();

  const handleTabChange = (tab: string) => {
    navigate(`../${tab}`);
  };

  const match = useMatch(`${path}:tabSlug?`);
  const selectedTabId = match?.params.tabSlug ?? 'todo';

  return {
    handleTabChange,
    selectedTabId,
  };
};

export const useTimeApprovalTabs = () => {
  return useApprovalTabs(`${timeApprovalsRoute}/`);
};

export const useEntriesApprovalTabs = () => {
  return useApprovalTabs(`${entriesApprovalsRoute}/`);
};
