import { Trans, useLingui } from '@lingui/react/macro';
import { RateCardEntryWithNestedDetails, RateCardWithNestedDetails, useUserDateFormat } from '@sit/client-shared';
import Button from '@web/components/Shared/Button';
import Textbox from '@web/components/Shared/Textbox/DebouncedTextbox';
import { dateWithFallback, stringWithFallback } from '@web/helpers/label';
import Box from 'carbon-react/lib/components/box';
import Form from 'carbon-react/lib/components/form';
import Typography from 'carbon-react/lib/components/typography';
import { parse } from 'date-fns';
import { useMemo } from 'react';
import styled from 'styled-components';
import DateInput from '../Shared/DateInput';
import DeleteButton from './components/FormDeleteButton';
import RateCardEntriesTable from './RateCardEntriesTable';
import { SelectProject } from '../Fields/Select/Project';
import { EditableRateCardEntry, mapToEditableRateCardEntries } from './helpers/transform';
import { RateCardFormPayload, useRateCardForm } from './hooks/useRateCardForm';
import { FormikErrors } from 'formik';

const FormRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: ${({ theme }) => theme.spacing * 2}px;
  margin-bottom: ${({ theme }) => theme.spacing}px;
`;

const NameField = styled(Textbox)``;

export type RateCardFormSubmitFn = (formPayload: RateCardFormPayload) => void | Promise<void>;

export type RateCardFormAction = 'dismiss-modal' | 'delete';

export type RateCardFormProps = {
  value: RateCardWithNestedDetails;
  entries: RateCardEntryWithNestedDetails[];
  mode?: 'edit' | 'create' | 'view';
  readonly?: boolean;
  onSubmit: RateCardFormSubmitFn;
  onAction: (action: RateCardFormAction, value: RateCardWithNestedDetails) => void;
};

type ReadonlyField = {
  label: React.ReactNode;
  value: React.ReactNode;
};

const ReadonlyField = ({ label, value }: ReadonlyField) => {
  return (
    <Box>
      <Typography variant="strong">{label}</Typography>
      <Typography truncate>{value}</Typography>
    </Box>
  );
};

function RateCardForm({ value, entries, mode = 'edit', readonly = false, onSubmit, onAction }: RateCardFormProps) {
  const { t } = useLingui();

  const dateFormat = useUserDateFormat();

  const initialValues = useMemo(
    () => ({
      rateCard: value,
      entries: mapToEditableRateCardEntries(entries),
      created: [],
      changed: [],
      deleted: [],
    }),
    [value, entries],
  );

  const {
    dirty,
    errors,
    isValid,
    handleSubmit,
    values: rateCardValue,
    handleEntryChange,
    handlewEntryCreate,
    handleEntryDelete,
    handleFieldChange,
  } = useRateCardForm(initialValues, async (value) => {
    await onSubmit(value);
  });

  const formHeight = mode === 'create' ? 'auto' : '550px';

  const isDeleteDisabled = useMemo(() => {
    return value.readOnly || entries.some((entry) => entry.readOnly);
  }, [value.readOnly, entries]);

  return (
    <Form
      stickyFooter
      height={formHeight}
      saveButton={
        mode !== 'view' || (dirty && !readonly) ? (
          <>
            <Button
              buttonType={{
                default: 'primary',
                embedded: 'secondary',
              }}
              disabled={!isValid || !dirty}
              onClick={() => {
                handleSubmit();
              }}
              ml={1}
            >
              {mode === 'create' ? <Trans>Create</Trans> : <Trans>Save</Trans>}
            </Button>
            {mode !== 'create' ? (
              <DeleteButton
                disabled={isDeleteDisabled}
                onAction={() => {
                  onAction('delete', rateCardValue.rateCard);
                }}
              />
            ) : null}
          </>
        ) : null
      }
      leftSideButtons={
        <Button
          buttonType={{
            default: 'tertiary',
            embedded: 'secondary',
          }}
          onClick={() => onAction('dismiss-modal', rateCardValue.rateCard)}
          mr={1}
        >
          <Trans>Close</Trans>
        </Button>
      }
    >
      <FormRoot>
        {!readonly && mode !== 'view' ? (
          <>
            <DateInput
              label={t`Effective date`}
              value={rateCardValue.rateCard.effectiveDate}
              onChange={(ev) => handleFieldChange({ effectiveDate: ev.target.value.rawValue ?? ev.target.value.formattedValue })}
              error={errors.rateCard?.effectiveDate}
            />
            <NameField
              label={t`Name`}
              defaultValue={rateCardValue.rateCard.name ?? ''}
              onValueChangeDebounced={(value) => handleFieldChange({ name: value })}
              debounceDelay={300}
              error={errors.rateCard?.name}
            />
            <SelectProject
              label={t`Project`}
              onChange={(project) => handleFieldChange({ project })}
              value={rateCardValue.rateCard.project?.id}
              width="100%"
              error={errors.rateCard?.project}
            />
          </>
        ) : (
          <>
            <ReadonlyField
              label={t`Effective date`}
              value={dateWithFallback(parse(rateCardValue.rateCard.effectiveDate, 'yyyy-MM-dd', new Date()), dateFormat)}
            />
            <ReadonlyField label={t`Name`} value={stringWithFallback(rateCardValue.rateCard.name)} />
            <ReadonlyField label={t`Project`} value={stringWithFallback(rateCardValue.rateCard.project?.name)} />
          </>
        )}
      </FormRoot>
      {mode !== 'create' ? (
        <RateCardEntriesTable
          onChange={handleEntryChange}
          onCreate={handlewEntryCreate}
          onRemove={handleEntryDelete}
          value={rateCardValue}
          readonly={readonly}
          errors={errors.entries as FormikErrors<EditableRateCardEntry>[]}
        />
      ) : null}
    </Form>
  );
}

export default RateCardForm;
