import { Trans, useLingui } from '@lingui/react/macro';
import Typography from 'carbon-react/lib/components/typography';
import styled from 'styled-components';
import ZeroState from '../../assets/img/zero-states/data-sources@2x.png';

const Root = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.spacing * 3}px;
`;

const Image = styled.img`
  height: 190px;
  object-fit: contain;
`;

const Title = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
`;

const SubTitle = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  margin: auto;
  width: 350px;
`;

export const DataSourcesZeroState = () => {
  const { t } = useLingui();
  return (
    <Root>
      <Image alt={t`Zero state`} src={ZeroState} />
      <Title mb={0}>
        <Trans>No time sources connected</Trans>
      </Title>
      <SubTitle mb={0}>
        <Trans>Connect any of the time sources listed to automate and enhance your timesheets with AI.</Trans>
      </SubTitle>
    </Root>
  );
};
