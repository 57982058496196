import { useLingui } from '@lingui/react/macro';
import { RateCardEntryWithNestedDetails, RateCardWithNestedDetails } from '@sit/client-shared';
import Dialog from '@web/components/Shared/Dialog';
import { useCallback, useState } from 'react';
import RateCardForm, { RateCardFormSubmitFn } from './RateCardsForm';

export interface ModalContentProps {
  value: RateCardWithNestedDetails;
  entries: RateCardEntryWithNestedDetails[];
  onClose: () => void;
  mode: 'edit' | 'create';
  onSave: RateCardFormSubmitFn;
  onDelete?: () => void;
  readonly?: boolean;
}

const RateCardModal = ({ value, entries, onClose, mode, onSave, onDelete = () => undefined, readonly = false }: ModalContentProps) => {
  const { t } = useLingui();
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    const animationTimeout = setTimeout(() => {
      onClose();
      clearTimeout(animationTimeout);
    }, 250);
  }, [onClose]);

  const effectiveMode = mode === 'create' ? 'create' : value.readOnly ? 'view' : 'edit';
  const title = mode === 'create' ? t`Create rate card` : value.readOnly ? t`View rate card` : t`Edit rate card`;

  const handleAction = (action: 'dismiss-modal' | 'delete') => {
    if (action === 'dismiss-modal') {
      handleClose();
    } else if (action === 'delete') {
      onDelete();
    }
  };

  const handleSave: RateCardFormSubmitFn = async (value) => {
    await onSave(value);
  };

  return (
    <Dialog open={isOpen} complexForm title={title} onCancel={handleClose} size="large">
      <RateCardForm
        mode={effectiveMode}
        readonly={readonly}
        value={value}
        entries={entries}
        onAction={handleAction}
        onSubmit={handleSave}
      />
    </Dialog>
  );
};

export default RateCardModal;
