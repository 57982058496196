import { Trans, useLingui } from '@lingui/react/macro';
import Typography from 'carbon-react/lib/components/typography';
import styled from 'styled-components';
import MaintenanceModeImage from '../../assets/img/maintenance-mode.svg';

const Root = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.space[3]};
  padding: ${({ theme }) => theme.space[5]};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.palette.black};
  text-align: center;
`;

const Image = styled.img`
  max-width: 530px;
  width: 100%;
`;

const MaintenanceModeComponent = ({ time }: { time: string }) => {
  const { t } = useLingui();
  return (
    <Root>
      <Typography variant="h1">
        <Trans>Sage Intelligent Time is down for scheduled maintenance</Trans>
      </Typography>
      <Typography variant="p">
        <Trans>
          Sage Intelligent Time is currently undergoing scheduled maintenance, please check back around <strong>{time}</strong>.
        </Trans>
      </Typography>
      <Image alt={t`Maintenance mode`} src={MaintenanceModeImage} />
    </Root>
  );
};

export default MaintenanceModeComponent;
