import { RateCardEntry, RateCardEntryWithNestedDetails } from '@sit/client-shared';

export type EditableRateCardEntry = RateCardEntry & { readOnly: boolean };
export const toEditableRateCardEntry = (entry: RateCardEntryWithNestedDetails): EditableRateCardEntry =>
  ({
    id: entry.id,
    employeeId: entry.employee?.id,
    itemId: entry.item?.id,
    rate: entry.rate,
    readOnly: entry.readOnly,
  }) as EditableRateCardEntry;

export const mapToEditableRateCardEntries = (entries: RateCardEntryWithNestedDetails[]): EditableRateCardEntry[] =>
  entries.map((e) => toEditableRateCardEntry(e));
