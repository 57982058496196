import { useLingui } from '@lingui/react/macro';
import { useGetTimesheet } from '@sit/client-shared';
import { getStringFromDate } from '@sit/core';
import { usePostTimesheetDuplicate } from '@web/api/timesheet/use-post-timesheet-duplicate';
import { useGetUser } from '@web/api/users/use-get-user';
import { Navigate, useNavigate } from 'react-router';
import toastr from '../../../helpers/toastr';
import { getStaffTimesheetsRoute, getTimesheetsRoute } from '../helpers/routes';
import { useTimesheetModals } from '../hooks';
import { CreateTimesheetModal, type FormData } from './CreateTimesheetModal';
import { mustHaveCreateStaffPermissions, mustHaveEditStaffPermissions, mustHaveEmployee, noEditPermissions } from './messages';

function useDuplicateData(timesheetId: string | undefined) {
  const { t } = useLingui();
  return useGetTimesheet(timesheetId, {
    select: (data) => {
      const today = getStringFromDate(new Date(), { ISO8601DateExtended: true });

      const { startDate: timesheetStart, endDate: timesheetEnd, description: timesheetDescription } = data;
      const startDate = timesheetStart ?? today;
      const endDate = timesheetEnd ?? today;
      const description = timesheetDescription ? t`${timesheetDescription} Copy` : t`Copy`;
      return {
        startDate,
        endDate,
        description,
      };
    },
  });
}

interface DuplicateTimesheetProps {
  staff: boolean;
  timesheetId: string | undefined;
  open: boolean;
}

export const DuplicateTimesheet = ({ staff, timesheetId, open }: DuplicateTimesheetProps) => {
  const { i18n, t } = useLingui();
  const navigate = useNavigate();
  const { closeModal } = useTimesheetModals();
  const { data } = useDuplicateData(timesheetId);
  const { mutate: duplicateTimesheet } = usePostTimesheetDuplicate();
  const { data: userData } = useGetUser();

  const handleSubmit = ({ startDate, endDate, description, includeEntries }: FormData) => {
    duplicateTimesheet(
      {
        body: {
          description,
          endDate: endDate,
          startDate: startDate,
        },
        pathParams: {
          id: timesheetId!,
        },
        queryParams: {
          includeEntries: includeEntries ? 'true' : 'false',
        },
      },
      {
        onSuccess: (data) => {
          navigate(`/timesheet/${data.id}${staff ? '?staff=true' : ''}`);
        },
      },
    );
  };

  if (open && userData) {
    if (!staff && (!userData.canEditTimesheets || !userData.canCreateTimesheet)) {
      const messageDescriptor = !userData.canEditTimesheets ? noEditPermissions : mustHaveEmployee;
      toastr.warning(i18n._(messageDescriptor));
      return <Navigate to={getTimesheetsRoute} />;
    }
    if (staff && (!userData.canEditStaffTimesheets || !userData.canCreateStaffTimesheets)) {
      const messageDescriptor = !userData.canEditStaffTimesheets ? mustHaveEditStaffPermissions : mustHaveCreateStaffPermissions;
      toastr.warning(i18n._(messageDescriptor));
      return <Navigate to={getStaffTimesheetsRoute} />;
    }
  }

  return (
    <CreateTimesheetModal
      title={t`Duplicate timesheet`}
      showIncludeEntriesBox={true}
      data={data}
      onClose={closeModal}
      onSubmit={handleSubmit}
      submitButtonText={t`Duplicate timesheet`}
      open={open}
    />
  );
};
