import { msg } from '@lingui/core/macro';
import { DimensionValueType, useGetDimensions } from '@sit/client-shared';
import { useMemo } from 'react';
import { buildDefaultSelect, SelectProps } from './buildSelect';
import { getDimension } from './helpers';

export interface LocationSelectProps extends SelectProps<DimensionValueType> {
  timesheetId?: string | null;
  companyId?: string;
  projectId: string | null | undefined;
}

const useQuery = ({ timesheetId, projectId, companyId }: LocationSelectProps) => {
  const result = useGetDimensions({
    timesheetId: timesheetId ?? undefined,
    projectId,
    companyId,
  });
  const values = getDimension(result.data, 'location')?.values;
  const data = useMemo(() => values?.filter((v) => !v.isArchived), [values]);
  return { ...result, data };
};

export const SelectLocation = buildDefaultSelect<DimensionValueType, LocationSelectProps>(useQuery, {
  testid: 'Select.Location',
  emptyLabel: msg`Select location`,
});
