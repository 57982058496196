import { ApolloProvider } from '@apollo/client';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { defaultLocale } from '@sit/core';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientOptions, PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import CarbonI18nProvider from 'carbon-react/lib/components/i18n-provider';
import { PropsWithChildren, Suspense, lazy } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import { BrowserRouter, useLocation } from 'react-router';
import { PersistGate } from 'redux-persist/integration/react';
import MaintenanceMode from './components/MaintenanceMode/MaintenanceMode';
import { NotificationGlobalHandler } from './components/Notifications/NotificationGlobalHandler';
import { NotificationHandlerProvider } from './components/Notifications/NotificationProvider';
import OverlayComponents from './components/OverlayComponents/OverlayComponents';
import { useAppApolloClient } from './graphql/apollo';
import { useInitializeClientShared } from './hooks/use-initialize-client-shared';
import { useCacheBustIndexDbIfDifferentUser } from './hooks/useBustCache';
import { useInitializeLaunchDarklyIdentifier } from './launchDarkly/launchDarklyIdentifierHook';
import { messages } from './lingui/en-US.po';
import { DEFAULT_CACHE_TIME, persister, queryClient } from './query-client';
import { persistor, store } from './redux/store';
import { useActivateUserLocale } from './services/lingui';
import { ThemeProvider } from './styles/theme';

const MouseTrap = lazy(() => import('./components/DeveloperSettings/DeveloperSettings'));

type AppProviderProps = Record<string, unknown>;

function AppProvidersWithRouting({ children }: PropsWithChildren<AppProviderProps>) {
  const location = useLocation();
  // Some routes specifically do not want to initialize Apollo because their authentication mechanism implementation
  // conflicts with the server's requirements for authentication. I.e., the server requires bearer token authentication
  // for GraphQL while some routes require SAML.
  // Since the SAML routes don't use Apollo at all, don't initialize the client.
  const skipApolloInitialization = ['/intacct-admin', '/saml'].some((pathname) => location.pathname.startsWith(pathname));
  const apolloClient = useAppApolloClient(skipApolloInitialization);

  return skipApolloInitialization ? (
    <>{children}</>
  ) : (
    <ApolloProvider client={apolloClient!}>
      <NotificationHandlerProvider>
        <NotificationGlobalHandler>{children}</NotificationGlobalHandler>
      </NotificationHandlerProvider>
    </ApolloProvider>
  );
}

const persistOptions: Omit<PersistQueryClientOptions, 'queryClient'> = {
  persister,
  buster: '1',
  maxAge: DEFAULT_CACHE_TIME,
  dehydrateOptions: {
    shouldDehydrateQuery: (query) => (typeof query.meta?.persist === 'boolean' ? query.meta.persist : false),
  },
};

// We have to load the default locale messages here because the i18n provider needs to be initialized with the default
i18n.loadAndActivate({ locale: defaultLocale, messages });

function InitWrapper({ children }: PropsWithChildren<AppProviderProps>) {
  useInitializeClientShared();
  useInitializeLaunchDarklyIdentifier();
  const { carbonLocale } = useActivateUserLocale();

  return <CarbonI18nProvider locale={carbonLocale}>{children}</CarbonI18nProvider>;
}

function PersistQueryClientInvalidator({ children }: PropsWithChildren<AppProviderProps>) {
  const { loading } = useCacheBustIndexDbIfDifferentUser();
  if (loading) return null;
  return <>{children}</>;
}

function AppProvidersWithStore({ children }: PropsWithChildren<AppProviderProps>) {
  return (
    <BrowserRouter>
      <PersistQueryClientProvider client={queryClient} persistOptions={persistOptions}>
        <I18nProvider i18n={i18n}>
          <PersistQueryClientInvalidator>
            <InitWrapper>
              <DndProvider backend={HTML5Backend}>
                <ThemeProvider>
                  <MaintenanceMode>
                    <Suspense fallback={null}>{import.meta.env.VITE_APP_SUPPORT_CHANGING_ORIGIN === 'true' && <MouseTrap />}</Suspense>
                    <AppProvidersWithRouting>{children}</AppProvidersWithRouting>
                    <OverlayComponents />
                  </MaintenanceMode>
                </ThemeProvider>
              </DndProvider>
              {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
            </InitWrapper>
          </PersistQueryClientInvalidator>
        </I18nProvider>
      </PersistQueryClientProvider>
    </BrowserRouter>
  );
}

const Gate = ({ children, persist = true }: { children: React.ReactNode; persist?: boolean }) =>
  persist ? (
    <PersistGate loading={null} persistor={persistor}>
      {children}
    </PersistGate>
  ) : (
    <>{children}</>
  );

export default function AppProviders({ children }: PropsWithChildren<AppProviderProps>): JSX.Element {
  const persistEnabled = Object.prototype.hasOwnProperty.call(store.getState(), '_persist');

  return (
    <Provider store={store}>
      <Gate persist={persistEnabled}>
        <AppProvidersWithStore>{children}</AppProvidersWithStore>
      </Gate>
    </Provider>
  );
}
