import { useLingui } from '@lingui/react/macro';
import { useHelpAboutUrl } from '@web/config/urls';
import Icon from 'carbon-react/lib/components/icon';
import IconButton from 'carbon-react/lib/components/icon-button';

export const HelpButton = () => {
  const { t } = useLingui();
  const helpUrl = useHelpAboutUrl();
  return (
    <IconButton data-pendo-id="help" aria-label="icon-button" onClick={() => window.open(helpUrl, '_blank', 'noopener,noreferrer')}>
      <Icon color="white" type="help" tooltipMessage={t`Help`} />
    </IconButton>
  );
};
