import { useLocation } from 'react-router';

export const useIsActiveTab = () => {
  const location = useLocation();

  const isActive = (path: string, searchIncludes?: string, searchExcludes?: string) => {
    if (
      location.pathname.startsWith(`/${path}`) &&
      (!searchIncludes || location.search.includes(searchIncludes)) &&
      (!searchExcludes || !location.search.includes(searchExcludes))
    )
      return true;
    return false;
  };

  return {
    isActive,
  };
};
