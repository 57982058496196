import { useLingui } from '@lingui/react/macro';
import { usePrevious } from '@sit/client-shared';
import { AppLocale } from '@sit/core';
import { reportError } from '@web/helpers/newrelic';
import { enUS as carbonEnUS } from 'carbon-react/lib/locales';
import { setDefaultOptions } from 'date-fns';
import { enUS as dateFnsEnUS } from 'date-fns/locale/en-US';
import { useCallback, useEffect, useState } from 'react';
import { messages as enUS } from '../lingui/en-US.po';
import { useUserLocale } from './locale';

const localeImports = {
  'de-DE': () => ({
    trans: import('../lingui/de-DE.po'),
    dateFns: import('date-fns/locale/de').then((module) => module.de),
    carbon: import('./dynamic/de-de'),
  }),
  'es-ES': () => ({
    trans: import('../lingui/es-ES.po'),
    dateFns: import('date-fns/locale/es').then((module) => module.es),
    carbon: import('./dynamic/es-es'),
  }),
  'fr-CA': () => ({
    trans: import('../lingui/fr-CA.po'),
    dateFns: import('date-fns/locale/fr-CA').then((module) => module.frCA),
    carbon: import('./dynamic/fr-ca'),
  }),
  'fr-FR': () => ({
    trans: import('../lingui/fr-FR.po'),
    dateFns: import('date-fns/locale/fr').then((module) => module.fr),
    carbon: import('./dynamic/fr-fr'),
  }),
} as const;

function useLoadAndActivateLocale() {
  const { i18n, t } = useLingui();
  const [carbonLocale, setCarbonLocale] = useState(carbonEnUS);
  const load = useCallback(
    (appLocale: AppLocale, dateFormat: string | undefined) => {
      if (appLocale === 'en-US' || appLocale === 'en-US-pseudo') {
        // Already loaded into bundle so no need to load
        // (These _might_ be redundant since we initialize at the top-level already).
        setDefaultOptions({ locale: dateFnsEnUS });
        i18n.loadAndActivate({ locale: appLocale, messages: enUS });
        return;
      }
      // Sanity check
      const localeImport = localeImports[appLocale];
      if (!localeImport) {
        reportError(new Error('Unsupported locale'), {
          appLocale,
        });
        return;
      }
      const { dateFns, trans, carbon } = localeImport();
      trans
        .then(({ messages }) => {
          i18n.loadAndActivate({ locale: appLocale, messages });
        })
        .catch((error) => {
          reportError(error, {
            appLocale,
          });
        });
      void Promise.all([
        dateFns
          .then((locale) => {
            setDefaultOptions({ locale: locale });
            return locale;
          })
          .catch((error) => {
            reportError(error, {
              appLocale,
            });
            return dateFnsEnUS;
          }),
        carbon
          .then((module) => module.default)
          .catch((error) => {
            reportError(error, {
              appLocale,
            });
            return carbonEnUS;
          }),
      ]).then(([dateFnsLocale, cLocale]) => {
        setCarbonLocale({
          ...cLocale,
          date: {
            ...cLocale.date,
            dateFnsLocale: () => dateFnsLocale,
            ariaLabels: {
              ...cLocale.date?.ariaLabels,
              previousMonthButton: () => t`Previous month`,
              nextMonthButton: () => t`Next month`,
            },
            dateFormatOverride: dateFormat,
          },
        });
      });
    },
    [i18n, t],
  );

  return { load, carbonLocale };
}

export function useActivateUserLocale() {
  const { locale, dateFormat } = useUserLocale();
  const previousLocale = usePrevious(locale);
  const { load, carbonLocale } = useLoadAndActivateLocale();
  useEffect(() => {
    if (locale === previousLocale) {
      return;
    }
    load(locale, dateFormat);
  }, [locale, dateFormat, load, previousLocale]);
  return {
    carbonLocale,
  };
}
