import { useLingui } from '@lingui/react/macro';
import { TimeClock, TimeClockBreak } from '@sit/client-shared';
import { startOfMinute } from 'date-fns';
import { FormikErrors } from 'formik';
import { useCallback } from 'react';
import { TimeClockDimensionField } from '../../ActiveClock/constants';

function useValidateRequiredFields() {
  const { t } = useLingui();
  return useCallback(
    (timeClock: TimeClock, fields: TimeClockDimensionField[]): FormikErrors<TimeClock> => {
      const errors: FormikErrors<TimeClock> = {};

      fields.forEach((field) => {
        if (field.required && !timeClock[field.type as keyof TimeClock]) {
          errors[field.type as keyof TimeClock] = t`This field is required`;
        }
      });

      return errors;
    },
    [t],
  );
}

function useValidateClockRange() {
  const { t } = useLingui();
  return useCallback(
    (timeClock: TimeClock): FormikErrors<TimeClock> => {
      const { clockOutTime, clockInTime } = timeClock;
      const clockInDate = startOfMinute(new Date(clockInTime));
      const clockOutDate = clockOutTime ? startOfMinute(new Date(clockOutTime)) : undefined;
      const now = startOfMinute(new Date());

      if (clockInDate.getTime() > now.getTime()) {
        return {
          clockInTime: t`Clock in time must be in the past`,
        };
      }

      if (clockOutDate) {
        if (clockOutDate.getTime() > now.getTime()) {
          return {
            clockOutTime: t`Clock out time must be in the past`,
          };
        }

        if (clockOutDate.getTime() < clockInDate.getTime()) {
          return {
            clockOutTime: t`Clock out time must be after clock in time`,
          };
        }
      }

      return {};
    },
    [t],
  );
}

function useValidateBreaks() {
  const { t } = useLingui();
  return useCallback(
    (timeClock: TimeClock): FormikErrors<TimeClock> => {
      const { clockOutTime, clockInTime } = timeClock;
      const clockInDate = startOfMinute(new Date(clockInTime));
      const clockOutDate = clockOutTime ? startOfMinute(new Date(clockOutTime)) : undefined;

      const breaks = timeClock.breaks
        .toReversed()
        .map<FormikErrors<TimeClockBreak>>((breakItem, i) => {
          const realIndex = timeClock.breaks.length - i - 1;
          const breakBegin = startOfMinute(new Date(breakItem.startTime));
          const breakEnd = breakItem.endTime ? startOfMinute(new Date(breakItem.endTime)) : undefined;
          const now = new Date();

          if (breakBegin.getTime() >= now.getTime()) {
            return {
              endTime: t`Break start time must be in the past`,
            };
          }

          if (breakBegin.getTime() < clockInDate.getTime()) {
            return {
              startTime: t`Break start time must be after clock in time`,
            };
          }

          if (breakEnd) {
            if (clockOutDate && breakEnd.getTime() > clockOutDate.getTime()) {
              return {
                endTime: t`Break end time must be before clock out time`,
              };
            }

            const previousBreak = timeClock.breaks[realIndex - 1];

            if (previousBreak) {
              const previousBreakStart = startOfMinute(new Date(previousBreak.startTime));

              if (breakEnd.getTime() > previousBreakStart.getTime()) {
                return {
                  endTime: t`Breaks must not overlap`,
                } satisfies FormikErrors<TimeClockBreak>;
              }
            }

            if (breakEnd.getTime() >= now.getTime()) {
              return {
                endTime: t`Break end time must be in the past`,
              } satisfies FormikErrors<TimeClockBreak>;
            }

            if (breakEnd.getTime() <= breakBegin.getTime()) {
              return {
                endTime: t`Break end time must be after break begin time`,
              } satisfies FormikErrors<TimeClockBreak>;
            }
          }

          return {};
        })
        .toReversed();

      const isEmpty = breaks.every((breakItem) => Object.keys(breakItem).length === 0);

      if (breaks.length === 0 || isEmpty) {
        return {};
      }

      return {
        breaks,
      };
    },
    [t],
  );
}

export function useValidateTimeClock(fields: TimeClockDimensionField[]): (timeClock: TimeClock) => FormikErrors<TimeClock> {
  const { t } = useLingui();
  const validateRequiredFields = useValidateRequiredFields();
  const validateClockRange = useValidateClockRange();
  const validateBreaks = useValidateBreaks();

  return (timeClock) => {
    const errors: FormikErrors<TimeClock> = {
      ...validateClockRange(timeClock),
      ...validateRequiredFields(timeClock, fields),
      ...validateBreaks(timeClock),
    };

    if (!timeClock.employee) {
      errors.employee = {
        id: t`Employee is required`,
      };
    }

    if (!timeClock.clockInTime) {
      errors.clockInTime = t`Clock in time is required`;
    }

    return errors;
  };
}
