import { useLingui } from '@lingui/react/macro';
import { Account, useDeleteApiAccountOptimistic } from '@sit/client-shared';
import { usePutApiAccountOptimistic } from '@web/api/usePutApiAccount';
import Button from '@web/components/Shared/Button';
import { useHelpAboutUrl } from '@web/config/urls';
import { timeSourcesRoutes } from '@web/containers/TimeSources/helpers/routes';
import useRetentionModal from '@web/containers/TimeSources/hooks/useRetentionModal';
import { ActionPopover, ActionPopoverItem } from 'carbon-react/lib/components/action-popover';
import { FlatTableCell, FlatTableRow } from 'carbon-react/lib/components/flat-table';
import Typography from 'carbon-react/lib/components/typography';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import StatusPill from '../../../components/StatusPill';
import Calendars from './Calendars';
import DeleteAccountModal from './DeleteAccountModal';
import { useCalendarStatusLocale } from './hooks';

const ProviderWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-weight: 600;
`;

const ImageWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-right: 15px;
  text-align: center;
  width: 33px;
`;

const ProviderLogoImage = styled.img`
  width: 33px;
`;

const ProviderName = styled.span`
  font-size: 14px;
`;

const AccountName = styled.div`
  font-weight: 600;
  font-size: 14px;
`;

const StatusMessage = styled.div`
  font-size: 14px;
`;

const ProviderMessageWrapper = styled.div`
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  min-width: 400px;
`;

const ProviderMessage = styled(Typography)`
  font-size: 14px;
`;

const ActionButton = styled(Button)`
  width: 100%;
`;

interface CalendarRowProps {
  account: Account;
}

function useProviderName(account: Account) {
  const { t } = useLingui();
  if (account.status === 'cal_not_yet_synced') return t`N/A`;
  if (account.provider === 'GOOGLE') return t`Google Calendar`;
  if (account.provider === 'MICROSOFT') return t`Outlook Calendar`;
  return t`N/A`;
}

const ProviderLogo = ({ account }: CalendarRowProps) => {
  const { t } = useLingui();
  const provider = useProviderName(account);

  return (
    <ProviderWrapper>
      <ImageWrapper>
        <ProviderLogoImage
          alt={account.provider === 'MICROSOFT' ? t`Microsoft Outlook` : t`Google Calendar`}
          height="33px"
          src={`${import.meta.env.VITE_APP_S3_URL}/${account.provider === 'MICROSOFT' ? 'microsoft-outlook.png' : 'google-calendar.png'}`}
        />
      </ImageWrapper>
      <ProviderName>{provider}</ProviderName>
    </ProviderWrapper>
  );
};

const useCalendarRow = ({ id, status, retention, calendars = [], provider_user_id }: Account) => {
  const { t } = useLingui();
  const navigate = useNavigate();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { buttonText, message, pillText, pillType, type } = useCalendarStatusLocale(status);

  const { mutate: deleteAccountOperation } = useDeleteApiAccountOptimistic();
  const { mutate: updateAccount } = usePutApiAccountOptimistic();

  const isRowExpandable = calendars.length > 0;
  const accountName = status === 'cal_not_yet_synced' || !provider_user_id ? t`N/A` : provider_user_id;

  const updateRetention = (retention: number) => {
    updateAccount({
      pathParams: {
        accountId: `${id}`,
      },
      body: {
        retention,
      },
    });
  };

  const { openModal } = useRetentionModal({
    type: 'calendar',
    title: t`Set data retention period for this account`,
    fieldLabel: t`Retain my calendar data for`,
    isEditing: true,
    onAction: updateRetention,
  });

  const handleRowAction = () => {
    if (type === 'CONNECT') {
      return navigate(timeSourcesRoutes.calendar);
    }

    return setShowDeleteModal(true);
  };

  const deleteAccount = (deletedClusters: boolean) => {
    deleteAccountOperation({
      pathParams: {
        accountId: `${id}`,
      },
      body: {
        deletedClusters,
      },
    });

    if (type === 'RECONNECT') navigate(timeSourcesRoutes.calendar);
  };

  const openRetentionModal = () => {
    openModal({
      retention: retention,
    });
  };

  const openDeleteModal = () => setShowDeleteModal(true);
  const closeDeleteModal = () => setShowDeleteModal(false);

  return {
    buttonText,
    message,
    pillText,
    pillType,
    type,
    accountName,
    openRetentionModal,
    openDeleteModal,
    closeDeleteModal,
    deleteAccount,
    handleRowAction,
    isRowExpandable,
    showDeleteModal,
  };
};

const CalendarRow = ({ account }: CalendarRowProps) => {
  const { t } = useLingui();
  const helpUrl = useHelpAboutUrl();

  const {
    buttonText,
    message,
    pillText,
    pillType,
    type,
    accountName,
    openRetentionModal,
    deleteAccount,
    handleRowAction,
    isRowExpandable,
    openDeleteModal,
    closeDeleteModal,
    showDeleteModal,
  } = useCalendarRow(account);

  return (
    <>
      <FlatTableRow
        key={account.id}
        expandable={isRowExpandable}
        expandableArea="firstColumn"
        subRows={[<Calendars key="flat-table-row-calendars" calendars={account.calendars} />]}
      >
        <FlatTableCell>
          <ProviderLogo account={account} />
        </FlatTableCell>
        <FlatTableCell>
          <AccountName>{accountName}</AccountName>
        </FlatTableCell>
        <FlatTableCell>
          <StatusMessage>
            <StatusPill text={pillText} type={pillType} />
          </StatusMessage>
        </FlatTableCell>
        <FlatTableCell>
          <ProviderMessageWrapper>
            <ProviderMessage mb={0}>{message}</ProviderMessage>
          </ProviderMessageWrapper>
        </FlatTableCell>
        <FlatTableCell>
          {buttonText && (
            <ActionButton onClick={handleRowAction} size="small">
              {buttonText}
            </ActionButton>
          )}
        </FlatTableCell>
        <FlatTableCell>
          <ActionPopover>
            <ActionPopoverItem onClick={openRetentionModal}>{t`Data retention period`}</ActionPopoverItem>
            <ActionPopoverItem icon="delete" onClick={openDeleteModal}>
              {t`Delete`}
            </ActionPopoverItem>
            <ActionPopoverItem icon="help" onClick={() => window.open(helpUrl, '_blank', 'noopener,noreferrer')}>
              {t`Help`}
            </ActionPopoverItem>
          </ActionPopover>
        </FlatTableCell>
      </FlatTableRow>
      {showDeleteModal && <DeleteAccountModal closeModal={closeDeleteModal} isReconnecting={type === 'RECONNECT'} onOk={deleteAccount} />}
    </>
  );
};

export default CalendarRow;
