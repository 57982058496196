import { invalidateUserQuery, queryKeyFn } from '@sit/client-shared';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { setAccessToken } from '../redux/authentication-slice';
import { useAppDispatch } from '../redux/store';

export function useUpdateAccessToken() {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  return useCallback(
    (accessToken: string) => {
      dispatch(setAccessToken(accessToken));
      return Promise.all([
        invalidateUserQuery(queryClient),
        queryClient.invalidateQueries({
          queryKey: queryKeyFn({
            path: '/companies',
            operationId: 'getApiCompany',
            variables: {},
          }),
        }),
      ]);
    },
    [queryClient],
  );
}
