import { Trans } from '@lingui/react/macro';
import Button from '@web/components/Shared/Button';
import Loader from 'carbon-react/lib/components/loader';

interface DeleteButtonProps {
  onAction?: (action: 'delete') => void;
  saving?: boolean;
}

function DeleteButton({ onAction, saving }: DeleteButtonProps) {
  const handleDelete = () => {
    if (onAction) {
      onAction?.('delete');
    }
  };

  return (
    <Button
      buttonType={{
        default: 'primary',
        embedded: 'secondary',
      }}
      onClick={() => handleDelete()}
      ml={1}
      destructive
    >
      {saving ? <Loader size="small" isInsideButton /> : <Trans>Delete</Trans>}
    </Button>
  );
}

export default DeleteButton;
