const trackEvents = {
  ACTIVITY_ASSIGN: 'ACTIVITY_ASSIGN',
  ACTIVITY_DELETE: 'ACTIVITY_DELETE',
  ACTIVITY_NODE_ASSIGN: 'ACTIVITY_NODE_ASSIGN',
  ACTIVITY_NODE_DELETE: 'ACTIVITY_NODE_DELETE',
  ACTIVITY_SPLIT: 'ACTIVITY_SPLIT',
  ACTIVITY_UPDATE: 'ACTIVITY_UPDATE',
  APPROVE_TIMESHEET_ENTRIES: 'APPROVE_TIMESHEET_ENTRIES',
  APPROVE_TIMESHEET: 'APPROVE_TIMESHEET',
  DATA_SOURCES_ADD: 'DATA_SOURCES_ADD',
  DATA_SOURCES_DELETE: 'DATA_SOURCES_DELETE',
  DATA_SOURCES_UPDATE: 'DATA_SOURCES_UPDATE',
  DECLINE_TIMESHEET_ENTRIES: 'DECLINE_TIMESHEET_ENTRIES',
  DECLINE_TIMESHEET: 'DECLINE_TIMESHEET',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGIN_INVALID_INPUT: 'LOGIN_INVALID_INPUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_VALID_INPUT: 'LOGIN_VALID_INPUT',
  LOGOUT: 'LOGOUT',
  NAVIGATE: 'NAVIGATE',
  PASSWORD_RESET: 'PASSWORD_RESET',
  RATECARD_CREATE: 'RATECARD_CREATE',
  RATECARD_ENTRY_CREATE: 'RATECARD_ENTRY_CREATE',
  SAVE_TIMESHEET_TIMED_OUT: 'SAVE_TIMESHEET_TIMED_OUT',
  TEAM_INVITE: 'TEAM_INVITE',
  TEAM_UPDATE_ROLE: 'TEAM_UPDATE_ROLE',
  TIME_ASSISTANT_DOWNLOADED: 'TIME_ASSISTANT_DOWNLOADED',
  TIMECLOCK_START: 'TIMECLOCK_START',
  TIMECLOCK_END: 'TIMECLOCK_END',
  TIMESHEET_CREATE: 'TIMESHEET_CREATE',
  TIMESHEET_DELETE: 'TIMESHEET_DELETE',
  TIMESHEET_DUPLICATE: 'TIMESHEET_DUPLICATE',
  TIMESHEET_ENTRY_CREATE: 'TIMESHEET_ENTRY_CREATE',
  TIMESHEET_ENTRY_UPDATE: 'TIMESHEET_ENTRY_UPDATE',
  TIMESHEET_RECALL: 'TIMESHEET_RECALL',
  TIMESHEET_SAVE: 'TIMESHEET_SAVE',
  TIMESHEET_SUBMIT: 'TIMESHEET_SUBMIT',
  TIMESHEET_UPDATE: 'TIMESHEET_UPDATE',
};

export default trackEvents;
