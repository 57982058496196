import { RateCardEntry, RateCardWithNestedDetails } from '@sit/client-shared';
import { FieldMetaProps, FormikErrors, useFormik } from 'formik';
import { v4 as randomUUID } from 'uuid';
import { EditableRateCardEntry } from '../helpers/transform';
import { useLingui } from '@lingui/react/macro';
export type RateCardFormSubmitFn = (formPayload: RateCardFormPayload) => void | Promise<void>;

export type RateCardFormPayload = {
  rateCard: RateCardWithNestedDetails;
  entries: EditableRateCardEntry[];
  created: string[];
  changed: string[];
  deleted: string[];
};

export type RateCardEntriesForm = {
  entries: EditableRateCardEntry[];
  created: string[];
  changed: string[];
  deleted: string[];
};

export function useRateCardForm(initialValues: RateCardFormPayload, onSubmit: RateCardFormSubmitFn) {
  const { t } = useLingui();
  const formControls = useFormik<RateCardFormPayload>({
    initialValues,
    isInitialValid: true,
    validate(values) {
      const errors: FormikErrors<RateCardFormPayload> = {};
      const entriesErrors: FormikErrors<EditableRateCardEntry>[] = [];

      values.entries.forEach((entry, index) => {
        if (!entry?.employeeId) {
          entriesErrors[index] = {
            employeeId: t`Employee is required`,
          };
        }
      });

      if (entriesErrors.length > 0) {
        errors.entries = entriesErrors;
      }

      return errors;
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        await onSubmit(values);
        resetForm({
          values: {
            rateCard: values.rateCard,
            entries: values.entries,
            created: [],
            changed: [],
            deleted: [],
          },
        });
      } catch (error) {
        // Any other validation error will be displayed in the toast.
      }
    },
  });

  const { handleSubmit, values, setFieldValue, setValues, getFieldMeta } = formControls;

  const handleFieldChange = (body: Partial<RateCardWithNestedDetails>) => {
    setFieldValue('rateCard', {
      ...values.rateCard,
      ...body,
    });
  };

  const handlewEntryCreate = () => {
    const rateCardEntry = {
      id: randomUUID(),
      rate: 0,
      readOnly: false,
    } as EditableRateCardEntry;

    setValues({
      ...values,
      entries: [...values.entries, rateCardEntry],
      created: [...values.created, rateCardEntry.id],
    });
  };

  const handleEntryDelete = (id: string) => {
    const entries = values.entries.filter((entry) => entry.id !== id);

    const newFormState = {
      ...values,
      entries,
    };

    setValues(newFormState);

    if (values.created.includes(id)) {
      setFieldValue(
        'created',
        values.created.filter((createdId) => createdId !== id),
      );
    } else {
      setFieldValue(`deleted.${values.deleted.length}`, id);
    }
  };

  const handleEntryChange = (entry: RateCardEntry) => {
    const entryIndex = values.entries.findIndex((e) => e.id === entry.id);

    if (entryIndex === -1) {
      setFieldValue(`entries.${values.entries.length}`, entry);
      return;
    }

    const { initialValue } = getFieldMeta(`entries.${entryIndex}`) as FieldMetaProps<RateCardEntry>;

    if (!values.created.includes(entry.id) && !values.changed.includes(entry.id)) {
      setFieldValue(`changed.${values.changed.length}`, entry.id);
    } else if (initialValue?.employeeId === entry.employeeId && initialValue?.itemId === entry.itemId) {
      setValues({
        ...values,
        changed: values.changed.filter((id) => id !== entry.id),
      });
    }

    setFieldValue(`entries.${entryIndex}`, entry);
  };

  return {
    ...formControls,
    handleSubmit,
    handleFieldChange,
    handlewEntryCreate,
    handleEntryDelete,
    handleEntryChange,
  };
}
