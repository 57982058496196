import { MessageDescriptor } from '@lingui/core';
import { useLingui } from '@lingui/react/macro';
import { RequestError } from '@sit/client-shared';
import { useShowIntacctId } from '@web/api/show-intacct-id';
import { CustomSelectChangeEvent, Option } from 'carbon-react/lib/components/select';
import { OptionProps } from 'carbon-react/lib/components/select/option';
import { useCallback, useMemo } from 'react';
import { MapOptions, mapOptionWithExternalId, prepareOptions, selectOptionId } from './helpers';
import { Root } from './shared/Root';
import { SelectField } from './shared/SelectField';
import { ValueWrapper } from './shared/ValueWrapper';

export interface SelectProps<T> {
  readOnly?: boolean;
  required?: boolean;
  disabled?: boolean;
  value?: string | null;
  fullWidth?: boolean;
  width?: string;
  onChange: (data: T | undefined) => void;
  onOpen?: () => void;
  label?: string;
  emptyLabel?: string | MessageDescriptor;
  error?: string;
  'data-testid'?: string;
}

interface UseData<T> {
  data: T | undefined;
  isLoading: boolean;
  isError: boolean;
  error: RequestError | undefined | null;
}

interface UseDataFn<T, Props extends SelectProps<T>> {
  useQuery: (props: Props) => UseData<readonly T[]>;
  findSelected: (data: T, value?: OptionProps['text']) => boolean;
  mapOptions: (data: T, config: MapOptions) => OptionProps;
  testid?: string;
  emptyLabel?: string | MessageDescriptor;
}

function buildSelect<T, P extends SelectProps<T>>(config: UseDataFn<T, P>) {
  const { useQuery, mapOptions } = config;

  const FetchDataSelect = (props: P) => {
    const { t } = useLingui();
    const { disabled, onChange, value, fullWidth = false, width, onOpen, label, required, error: errorMsg, readOnly } = props;
    const { data, isLoading, isError, error } = useQuery(props);
    const emptyLabel = props.emptyLabel ?? config.emptyLabel;
    const testid = props['data-testid'] ?? config.testid ?? 'Custom-Select';

    const showIntacctId = useShowIntacctId();

    const handleMapOptions = useCallback<(option: T) => OptionProps>(
      (option) => mapOptions(option, { showExternalId: showIntacctId }),
      [showIntacctId],
    );

    const { options, byValue } = useMemo(
      () =>
        data
          ? prepareOptions(data, handleMapOptions)
          : {
              options: [],
              byValue: new Map(),
            },
      [data, handleMapOptions],
    );

    const handleChange = useCallback(
      (event: CustomSelectChangeEvent) => {
        // `event.selectionConfirmed` is a custom property in the `FilterableSelect` component's `onChange` callback
        // to indicate that the user has selected an option.
        // We don't want to call the component callback's `onChange` if the user has not selected an option.
        // Without this check, every time the user types a character, the component callback's `onChange` will be called
        // and it will subsequently assign the value to the first item in the dropdown list.
        // However, we do want to call the `onChange` callback if the user has cleared the input field.
        const hasCleared = event.target.value === '';
        if (event.target.value == null || (!hasCleared && event.selectionConfirmed === false)) return;
        const selected = byValue.get(event.target.value.toString());
        onChange(selected);
      },
      [byValue, onChange],
    );

    if (isError) {
      const errorMessage: string | undefined = (error as any)?.message || (error as any)?.payload || t`Can't load data`;
      return <div>{errorMessage}</div>;
    }

    const selectData = value ? byValue.get(value) : undefined;

    const { text, value: innerValue } = selectData ? handleMapOptions(selectData) : { text: '', value: '' };

    const customWidth = fullWidth ? '100%' : (width ?? '200px');
    const emptyLabelText = !emptyLabel || typeof emptyLabel === 'string' ? emptyLabel : t(emptyLabel);
    const placeholder = text || emptyLabelText || t`Type to search`;

    return (
      <Root>
        <ValueWrapper width={customWidth} data-pendo-id={testid} data-testid={testid} disabled={disabled}>
          <SelectField
            // We don't use `readOnly` to allow for the placeholder to be shown
            disabled={disabled}
            enableVirtualScroll
            error={errorMsg}
            isLoading={isLoading}
            label={label}
            maxWidth="100%"
            onChange={handleChange}
            onOpen={onOpen}
            placeholder={placeholder}
            readOnly={readOnly}
            required={required}
            value={innerValue}
          >
            {options.map((option, index) => (
              <Option key={testid + (option.id ?? index)} value={option.value} text={option.text} />
            ))}
          </SelectField>
        </ValueWrapper>
      </Root>
    );
  };

  return FetchDataSelect;
}

interface DefaultExternalIdData {
  id: string;
  externalId: string | null;
  name: string;
}

export function buildDefaultSelect<T extends DefaultExternalIdData, P extends SelectProps<T>>(
  useQuery: (props: P) => UseData<readonly T[]>,
  config: Partial<UseDataFn<T, P>> = {},
) {
  return buildSelect<T, P>({
    useQuery,
    findSelected: selectOptionId,
    mapOptions: mapOptionWithExternalId,
    ...config,
  });
}
