import { RateCard, RateCardWithNestedDetails, usePostRateCards } from '@sit/client-shared';
import RateCardModal from '@web/components/RateCards/RateCardModal';
import { RateCardFormSubmitFn } from '@web/components/RateCards/RateCardsForm';
import { useCurrentEntity } from '@web/hooks/use-current-entity';
import { add, format } from 'date-fns';
import { useNavigate } from 'react-router';
import { v4 as randomUUID } from 'uuid';
import { getRateCardIdRoute, rateCardRoute } from './helpers/routes';
import { useLingui } from '@lingui/react/macro';
import { track } from '@web/analytics/analyticsClient';
import trackEvents from '@web/analytics/trackEvents';

const toRateCard = (rateCard: RateCardWithNestedDetails): RateCard => ({
  id: rateCard.id,
  companyId: rateCard.companyId,
  name: rateCard.name,
  effectiveDate: rateCard.effectiveDate,
  projectId: rateCard.project?.id,
});

function NewRateCard() {
  const companyId = useCurrentEntity()?.id;
  const navigate = useNavigate();
  const { t } = useLingui();

  const { mutateAsync } = usePostRateCards();

  if (!companyId) {
    throw new Error(t`Company ID not found`);
  }

  const value: RateCardWithNestedDetails = {
    id: randomUUID(),
    companyId: companyId,
    name: '',
    effectiveDate: format(add(new Date(), { days: 1 }), 'yyyy-MM-dd'),
    readOnly: false,
  };

  const handleHideModal = () => {
    const hasHistory: boolean = window.history.state && window.history.state.idx > 0;

    if (hasHistory) {
      window.history.back();
    } else {
      navigate(rateCardRoute, { replace: true });
    }
  };

  const handleSave: RateCardFormSubmitFn = async ({ rateCard }) => {
    await mutateAsync({
      body: {
        ...toRateCard(rateCard),
      },
    });
    track(trackEvents.RATECARD_CREATE);
    navigate(getRateCardIdRoute(rateCard.id), { replace: true });
  };

  return <RateCardModal value={value} entries={[]} mode="create" onClose={handleHideModal} onSave={handleSave} />;
}

export default NewRateCard;
