import { Trans, useLingui } from '@lingui/react/macro';
import { RateCardEntry } from '@sit/client-shared';
import TextBox from '@web/components/Shared/Textbox/DebouncedTextbox';
import { ActionPopover, ActionPopoverItem } from 'carbon-react/lib/components/action-popover';
import Box from 'carbon-react/lib/components/box';
import {
  FlatTable,
  FlatTableBody,
  FlatTableCell,
  FlatTableHead,
  FlatTableHeader,
  FlatTableProps,
  FlatTableRow,
} from 'carbon-react/lib/components/flat-table';
import Typography from 'carbon-react/lib/components/typography';
import styled, { css } from 'styled-components';
import { SelectEmployee } from '../Fields/Select/Employee';
import Button from '../Shared/Button';
import { SelectItem } from '../Fields/Select/Item';
import { EditableRateCardEntry } from './helpers/transform';
import { RateCardEntriesForm } from './hooks/useRateCardForm';
import { FormikErrors } from 'formik';

interface StyledBreakTableProps extends FlatTableProps {
  fullWidth: boolean;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing * 2}px;
`;

const EntriesTableContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const fullWidthStyle = css`
  width: 100%;
`;

const defaultWidthStyle = css``;

const BreakTable = styled<(props: StyledBreakTableProps) => React.JSX.Element>(FlatTable)`
  ${({ fullWidth }) => (fullWidth ? fullWidthStyle : defaultWidthStyle)}
`;

interface RateCardEntriesTableProps {
  value: RateCardEntriesForm;
  fullWidth?: boolean;
  readonly?: boolean;
  onChange: (entry: RateCardEntry) => void;
  onRemove: (id: string) => void;
  onCreate: () => void;
  errors?: FormikErrors<EditableRateCardEntry>[];
}

interface EditableContentProps {
  values: EditableRateCardEntry[];
  onValueChange?: (body: RateCardEntry) => void;
  onValueDelete?: (id: string) => void;
  readonly?: boolean;
  errors?: FormikErrors<EditableRateCardEntry>[];
}

const EditContent = ({ values, errors = [], onValueChange, onValueDelete, readonly = false }: EditableContentProps) => {
  const { t } = useLingui();
  return (
    <>
      {values.map((entry, index) => (
        <FlatTableRow key={entry.id}>
          <FlatTableCell pt={2}>
            <SelectEmployee
              value={entry.employeeId}
              readOnly={readonly || entry.readOnly}
              error={errors[index]?.employeeId}
              onChange={(employee) => {
                if (!employee) {
                  return;
                }
                onValueChange?.({
                  ...entry,
                  employeeId: employee.id,
                });
              }}
            />
          </FlatTableCell>
          <FlatTableCell pt={2}>
            <SelectItem
              readOnly={readonly || entry.readOnly}
              value={entry.itemId}
              error={errors[index]?.itemId}
              onChange={(item) => {
                if (!item) {
                  return;
                }

                onValueChange?.({
                  ...entry,
                  itemId: item.id,
                });
              }}
            />
          </FlatTableCell>
          <FlatTableCell>
            <TextBox
              readOnly={readonly || entry.readOnly}
              mt={2}
              defaultValue={`${entry.rate}`}
              error={errors[index]?.rate}
              onValueChangeDebounced={(rate) => {
                onValueChange?.({
                  ...entry,
                  rate: Number(rate),
                });
              }}
            />
          </FlatTableCell>
          <FlatTableCell pb={1}>
            {!readonly && !entry.readOnly && (
              <ActionPopover>
                <ActionPopoverItem onClick={() => onValueDelete?.(entry.id)}>{t`Delete`}</ActionPopoverItem>
              </ActionPopover>
            )}
          </FlatTableCell>
        </FlatTableRow>
      ))}
    </>
  );
};

const RateCardEntriesTable = ({
  value,
  errors,
  fullWidth = false,
  readonly = false,
  onChange: handleEntryChange,
  onCreate: createNewEntry,
  onRemove: removeEntry,
}: RateCardEntriesTableProps) => {
  return (
    <Root>
      <EntriesTableContainer>
        <Typography variant="segment-header">
          <Trans>Entries</Trans>
        </Typography>

        {!readonly && (
          <Button size="small" buttonType="primary" onClick={() => createNewEntry()}>
            <Trans>New entry</Trans>
          </Button>
        )}
      </EntriesTableContainer>
      <BreakTable fullWidth={fullWidth} colorTheme="transparent-white">
        <FlatTableHead>
          <FlatTableRow>
            <FlatTableHeader>
              <Box justifyContent="space-between" alignItems="center" display="flex">
                <Trans>Employee</Trans>
              </Box>
            </FlatTableHeader>
            <FlatTableHeader>
              <Box justifyContent="space-between" alignItems="center" display="flex">
                <Trans>Item</Trans>
              </Box>
            </FlatTableHeader>
            <FlatTableHeader width={130}>
              <Box justifyContent="space-between" alignItems="center" display="flex">
                <Trans>Rate</Trans>
              </Box>
            </FlatTableHeader>
            <FlatTableHeader width={85} />
          </FlatTableRow>
        </FlatTableHead>
        <FlatTableBody>
          {value.entries.length === 0 && (
            <FlatTableRow>
              <FlatTableCell align="center" colspan={4}>
                <Trans>No entries recorded for this rate card</Trans>
              </FlatTableCell>
            </FlatTableRow>
          )}
          <EditContent
            values={value.entries}
            errors={errors}
            readonly={readonly}
            onValueChange={handleEntryChange}
            onValueDelete={removeEntry}
          />
        </FlatTableBody>
      </BreakTable>
    </Root>
  );
};

export default RateCardEntriesTable;
