import { useLingui } from '@lingui/react/macro';
import { usePostApiForgotPassword } from '@sit/client-shared';
import JoinUsDialog from '@web/components/JoinUsDialog';
import { useFlag } from '@web/hooks/useFlags';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router';
import PublicPage from '../../components/Shared/Public/PublicPage';
import ResetPasswordForm, { ForgotPasswordFormValues } from './ResetPasswordForm';
import ResetPasswordSuccess from './ResetPasswordSuccess';
import { config } from '@web/config/env';

function isRedirectToIntacctError(error: Error | undefined): error is Error & { message: `${string}Intacct credentials${string}` } {
  return error?.message.includes('Intacct credentials') ?? false;
}

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { t } = useLingui();
  const timeoutRef = useRef<number | null>(null);

  // Assume enabled on initial load before flag is fetched
  const pageDisabled = !(useFlag('ENABLE_PASSWORD_LOGIN') ?? true);
  const [searchParams, setSearchParams] = useSearchParams();
  const companyIdParam = searchParams.get('companyId') ?? '';
  const userIdParam = searchParams.get('userId') ?? '';
  const resetSent = searchParams.get('resetSent');

  const [formData, setFormData] = useState<ForgotPasswordFormValues>({
    username: userIdParam,
    company: companyIdParam,
  });
  const { mutate: forgotPassword, error, isLoading } = usePostApiForgotPassword();

  const handleCompanyBlur = useCallback(
    (companyId: string) => {
      if (!companyId) return;
      // Clear any previous timeout to cancel the redirect to Intacct forgot password page.
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      // When the user is done typing in the company, we want to check if Intacct authentication is required.
      // If so, we redirect to the Intacct login page.
      forgotPassword(
        {
          body: {
            username: '____USER_NOT_EXISTS____',
            company: companyId,
          },
          requestConfig: {
            skipToast: true,
          },
        },
        {
          onError: (error) => {
            if (isRedirectToIntacctError(error)) {
              timeoutRef.current = setTimeout(() => {
                // Currently, from what I can gather, Intacct's forgot password page does not support the `companyId` query parameter.
                // eslint-disable-next-line react-compiler/react-compiler -- navigating to a different domain
                window.location.href = `${config.VITE_APP_INTACCT_WEB_IA_ACCT}/resetpassword.phtml?.done=frameset.phtml`;
              }, 5000) as unknown as number;
            }
          },
        },
      );
    },
    [forgotPassword],
  );

  useEffect(() => {
    // On page load, we want to check if the company should use Intacct authentication.
    handleCompanyBlur(companyIdParam);
    // If the user clicks `Cancel` between the time the user is redirected to the Intacct forgot password page and the time the user is directed back to the login page,
    // we want to clear the timeout to prevent the user from being redirected back to the Intacct forgot password page.
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
    // All of these are stable dependencies, so this will only run once.
  }, [companyIdParam, handleCompanyBlur]);

  const handleSubmit = (data: ForgotPasswordFormValues) => {
    setFormData(data);
    forgotPassword({
      body: {
        username: data.username,
        company: data.company,
      },
    });
    setSearchParams({ ...searchParams, resetSent: 'true' });
  };

  const returnToLogin = () => {
    navigate('/login');
  };

  const formMessage = !error
    ? undefined
    : isRedirectToIntacctError(error)
      ? {
          variant: 'info' as const,
          message: t`Intacct credentials are required for this company. You will be redirected to the Intacct login page.`,
        }
      : {
          variant: 'error' as const,
          message: error.message,
        };

  return (
    <>
      <Helmet>
        <title>{t`Forgot Password | Sage Intelligent Time`}</title>
      </Helmet>
      <PublicPage>
        <JoinUsDialog closeable={!pageDisabled} />
        {resetSent === 'true' ? (
          <ResetPasswordSuccess resend={() => handleSubmit(formData)} returnToLogin={returnToLogin} />
        ) : (
          <ResetPasswordForm
            onSubmit={handleSubmit}
            initialValues={{
              company: companyIdParam,
              username: userIdParam,
            }}
            disabled={pageDisabled}
            // The extra check for `formData.company` and `formData.username` is to prevent the button to show
            // a loading state when checking whether they should be redirected to the Intacct forget password page.
            loading={isLoading && !!formData.company && !!formData.username}
            formMessage={formMessage}
            onCancel={returnToLogin}
            onCompanyBlur={handleCompanyBlur}
          />
        )}
      </PublicPage>
    </>
  );
};

export default ForgotPassword;
