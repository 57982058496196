import { Trans, useLingui } from '@lingui/react/macro';
import { useDeleteApiAccountOptimistic, useGetUserSettings } from '@sit/client-shared';
import { usePutApiAccountOptimistic } from '@web/api/usePutApiAccount';
import Button from '@web/components/Shared/Button';
import { useHelpAboutUrl } from '@web/config/urls';
import { timeSourcesRoutes } from '@web/containers/TimeSources/helpers/routes';
import useRetentionModal from '@web/containers/TimeSources/hooks/useRetentionModal';
import { isNotSupported } from '@web/helpers/operatingSystem';
import { ActionPopover, ActionPopoverItem } from 'carbon-react/lib/components/action-popover';
import { FlatTableCell, FlatTableRow } from 'carbon-react/lib/components/flat-table';
import Typography from 'carbon-react/lib/components/typography';
import { intlFormatDistance } from 'date-fns';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import TimeAssistantLogo from '../../../assets/img/icons/time-assistant-logo.svg';
import StatusPill from '../../../components/StatusPill';
import { useCanOpenTA } from '../hooks/useCanOpenTimeAssistant';
import DeleteAccountModal from './DeleteAccountModal';
import { useAssistantStatusLocale } from './hooks';

const ProviderWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-weight: 600;
`;

const ImageWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-right: 15px;
  text-align: center;
  width: 33px;
`;

const ProviderLogo = styled.img`
  width: 33px;
`;

const AccountName = styled.div`
  font-weight: 600;
  font-size: 14px;
`;

const StatusMessage = styled.div`
  font-size: 14px;
`;

const ProviderMessageWrapper = styled.div`
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  min-width: 400px;
  overflow: 'hidden';
  text-overflow: 'ellipsis';
  width: 420px;
`;

const ProviderMessage = styled(Typography)`
  font-size: 14px;
`;

const DeleteButton = styled(Button)`
  width: 100%;
`;

interface SageAssistantRowProps {
  albert: string | undefined;
  lastActivity: string | null;
}

const useAssistantRow = (albert: string | undefined) => {
  const { t } = useLingui();
  const navigate = useNavigate();
  const canOpenTimeAssistant = useCanOpenTA();
  const { data: userSettings } = useGetUserSettings();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { mutate: deleteAccountOperation } = useDeleteApiAccountOptimistic();
  const { mutate: updateAccount } = usePutApiAccountOptimistic();
  const { buttonText, message, pillText, pillType, type } = useAssistantStatusLocale(albert);
  const activitiesRetention = userSettings?.activitiesRetention;

  const updateRetention = (retention: number) => {
    updateAccount({
      pathParams: {
        accountId: 'tracker',
      },
      body: {
        retention,
      },
    });
  };

  const { openModal } = useRetentionModal({
    type: 'activities',
    title: t`Set data retention period for this account`,
    fieldLabel: t`Retain my Time Assistant data for`,
    isEditing: true,
    onAction: updateRetention,
  });

  const buttonAction = () => {
    switch (type) {
      case 'DOWNLOAD':
        navigate('time-assistant');
        break;
      default: {
        if (canOpenTimeAssistant) {
          // eslint-disable-next-line react-compiler/react-compiler -- navigating to a different domain
          window.location.href = 'sittracker://param';
        }
      }
    }
  };

  const deleteAccount = (deletedClusters: boolean) => {
    deleteAccountOperation({
      pathParams: {
        accountId: 'tracker',
      },
      body: {
        deletedClusters,
      },
    });
  };

  const openRetentionModal = () => {
    openModal({ retention: activitiesRetention });
  };

  const downloadAssistant = () => {
    navigate(timeSourcesRoutes.timeAssistant);
  };

  const openDeleteModal = () => setShowDeleteModal(true);
  const closeDeleteModal = () => setShowDeleteModal(false);

  return {
    showDeleteModal,
    openDeleteModal,
    closeDeleteModal,
    deleteAccount,
    openRetentionModal,
    buttonText,
    message,
    pillText,
    pillType,
    buttonAction,
    downloadAssistant,
  };
};

const SageAssistantRow = (props: SageAssistantRowProps) => {
  const { t } = useLingui();
  const helpUrl = useHelpAboutUrl();

  const {
    showDeleteModal,
    openDeleteModal,
    closeDeleteModal,
    deleteAccount,
    openRetentionModal,
    buttonText,
    message,
    pillText,
    pillType,
    buttonAction,
    downloadAssistant,
  } = useAssistantRow(props.albert);

  const lastActivityMessage = props.lastActivity && (
    <>
      <br />
      <small>
        {t`Last synced`} {intlFormatDistance(Date.parse(props.lastActivity), Date.now())}.
      </small>
    </>
  );

  return (
    <>
      <FlatTableRow key="sage-assistant">
        <FlatTableCell>
          <ProviderWrapper>
            <ImageWrapper>
              <ProviderLogo alt={t`Time Assistant logo`} src={TimeAssistantLogo} />
            </ImageWrapper>
            <Trans>Time Assistant</Trans>
          </ProviderWrapper>
        </FlatTableCell>
        <FlatTableCell>
          <AccountName>
            <Trans>N/A</Trans>
          </AccountName>
        </FlatTableCell>
        <FlatTableCell>
          <StatusMessage>
            <StatusPill text={pillText} type={pillType} />
          </StatusMessage>
        </FlatTableCell>
        <FlatTableCell>
          <ProviderMessageWrapper>
            <ProviderMessage mb={0}>
              {message} {lastActivityMessage}
            </ProviderMessage>
          </ProviderMessageWrapper>
        </FlatTableCell>
        <FlatTableCell>
          {buttonText && (
            <DeleteButton disabled={isNotSupported} onClick={buttonAction} size="small">
              {buttonText}
            </DeleteButton>
          )}
        </FlatTableCell>
        <FlatTableCell>
          <ActionPopover>
            <ActionPopoverItem onClick={openRetentionModal}>{t`Data retention period`}</ActionPopoverItem>
            <ActionPopoverItem icon="download" onClick={downloadAssistant}>
              {t`Re-download`}
            </ActionPopoverItem>
            <ActionPopoverItem icon="delete" onClick={openDeleteModal}>
              {t`Delete`}
            </ActionPopoverItem>
            <ActionPopoverItem icon="help" onClick={() => window.open(helpUrl, '_blank', 'noopener,noreferrer')}>
              {t`Help`}
            </ActionPopoverItem>
          </ActionPopover>
        </FlatTableCell>
      </FlatTableRow>
      {showDeleteModal && <DeleteAccountModal type="tracker" onOk={deleteAccount} closeModal={closeDeleteModal} />}
    </>
  );
};

export default SageAssistantRow;
