import {
  buildAutoslideLoginUrlWithNavop,
  buildAutoslideLoginUrlWithRecordUrl,
  buildMobileDeepLinkUrl,
  type NavopValue,
} from '@sit/intacct-web';
import ErrorBoundary from '@web/components/ErrorBoundary';
import { config } from '@web/config/env';
import { ErrorFallback } from '@web/containers/EntriesApprovals/Error';
import { useFragmentParams } from '@web/helpers/routing';
import { Suspense, useEffect } from 'react';

function useDeepLinkFragments() {
  const p = useFragmentParams();
  const navopStr = p.get('navop');
  const companyId = p.get('companyId');
  const loginId = p.get('loginId');
  const r = p.get('r'); // `r` in: https://www.intacct.com/ia/acct/ur.phtml?.r=s8mBYsNwpUxvbWbNJ9Oj-Vom1OBMzgynEbFNYHhdodf
  if (!companyId || !navopStr) {
    throw new Error('Missing required fragment parameters');
  }
  const navop = navopStr ? Number(navopStr) : undefined;
  if (!navop || !Number.isInteger(navop)) {
    throw new Error('Invalid navop value');
  }
  p.delete('navop');
  p.delete('companyId');
  p.delete('loginId');
  p.delete('r');

  return { navop: navop as NavopValue, companyId, loginId, fragmentParams: p, r };
}

/**
 * Custom hook that navigates to a deep link based on the platform.
 *
 * This hook attempts to navigate to a mobile deep link immediately. If the navigation
 * to the mobile deep link fails within 50 milliseconds, it falls back to navigating
 * to a web deep link.
 *
 * @param mobileDeepLink - The URL for the mobile deep link.
 * @param webDeepLink - The URL for the web deep link.
 *
 * @example
 * useNavigateToDeepLink('myapp://path', 'https://myapp.com/path');
 */
function useNavigateToDeepLink(mobileDeepLink: string, webDeepLink: string) {
  useEffect(() => {
    const now = Date.now();
    const timeout = setTimeout(() => {
      if (Date.now() - now > 100) return;
      window.location.href = webDeepLink;
    }, 50);
    window.location.href = mobileDeepLink;
    return () => clearTimeout(timeout);
  }, [mobileDeepLink, webDeepLink]);
}

function useDeepLink() {
  const { navop, companyId, loginId, r, fragmentParams } = useDeepLinkFragments();
  const mobileDeepLink = buildMobileDeepLinkUrl({ navop, params: fragmentParams });
  const intacctDeepLink = r
    ? buildAutoslideLoginUrlWithRecordUrl(r, `${config.VITE_APP_INTACCT_WEB_IA_ACCT}/ur.phtml`)
    : buildAutoslideLoginUrlWithNavop(companyId, loginId, navop, `${config.VITE_APP_INTACCT_WEB_IA_ACCT}/login.phtml`); // TODO: handle additional query params
  useNavigateToDeepLink(mobileDeepLink, intacctDeepLink);
}

function DeepLinking() {
  useDeepLink();
  return null;
}

const DeepLinkingPage = () => {
  return (
    <ErrorBoundary fallbackUI={(error) => <ErrorFallback error={error} />}>
      <Suspense fallback={null}>
        <DeepLinking />
      </Suspense>
    </ErrorBoundary>
  );
};

export default DeepLinkingPage;
