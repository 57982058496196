import { useLocation } from 'react-router';

export const readSlugFromPathName = (pathName: string, position = 1) => {
  // example pathName: "/time-preferences/time-gathering" should return "time-gathering"

  if (!pathName || typeof pathName !== 'string') return null;

  const firstSplit = pathName.split('/');

  if (!Array.isArray(firstSplit) || firstSplit.length < 3) return null;

  const positionOfSlug = position + 1;

  return firstSplit[positionOfSlug];
};

export function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export function useFragmentParams() {
  const searchFragment = useLocation().hash.substring(1);

  return new URLSearchParams(decodeURIComponent(searchFragment));
}

const urlRegExp = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    'localhost|' + // localhost
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', // fragment locator
  'i',
);

/**
 * Returns a Boolean value that indicates whether or not a string is a valid URL
 * @param value the string to validate
 * @returns true if the string is a valid URL, false otherwise
 */
export const validateUrl = (value: string) => urlRegExp.test(value);
