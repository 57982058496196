import { createSelector } from '@reduxjs/toolkit';
import { GetApiDimensionsResponse } from '@sit/client-shared';
import { ReduxStore } from '../../types/redux/state';
import { BuildType, DevOverrides } from '../Development';

export * from './autofill';

const getClusterAssignModal = (state: ReduxStore) => state.clusterAssignModal || {};
const getToken = (state: ReduxStore) => state.user.token;
const getRefreshToken = (state: ReduxStore) => state.user.refreshToken;
// Dimension related
const getBuildType = (state: ReduxStore) => state.development.buildType;
const getDevOverrides = (state: ReduxStore) => state.development.devOverrides;
const getTimesheetStatusStoreData = (state: ReduxStore) => state.timesheetStatus;

// helpers
export const findDepartmentDimension = (dimensionsData: GetApiDimensionsResponse | undefined) =>
  (dimensionsData || []).find((d) => d.integrationMeta?.objectName === 'DEPARTMENT');
export const findLocationDimension = (dimensionsData: GetApiDimensionsResponse | undefined) =>
  (dimensionsData || []).find((d) => d.integrationMeta?.objectName === 'LOCATION');

export const selectIsAuthenticated = createSelector(getToken, (token) => token != null);
export const selectToken = createSelector(getToken, (token) => token);
export const selectRefreshToken = createSelector(getRefreshToken, (refreshToken) => refreshToken);

/**
 *     Cluster Assign modal
 */
export const selectClusterAssignModal = createSelector(getClusterAssignModal, (cam) => cam);
export const selectShouldDisplayClusterAssignModal = createSelector(getClusterAssignModal, (cam) => cam.visible);

/**
 *     Clusters
 */
export const selectBuildType = createSelector(getBuildType, (buildType): BuildType => {
  return buildType;
});

export const selectDevOverrides = createSelector(getDevOverrides, (overrides): DevOverrides => {
  return overrides;
});

export const selectIsTimesheetSaving = createSelector(
  getTimesheetStatusStoreData,
  ({ pendingResponses }): boolean => Object.keys(pendingResponses).length > 0,
);
