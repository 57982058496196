import { userEntityComparator } from '@sit/client-shared';
import { formatIntacctEntityName } from '@web/helpers/entity';
import { useCompanies } from '@web/redux/hooks/user';
import Box from 'carbon-react/lib/components/box';
import Icon from 'carbon-react/lib/components/icon';
import CarbonPopoverContainer from 'carbon-react/lib/components/popover-container';
import { Fragment, useState } from 'react';
import styled, { css } from 'styled-components';
import { useQueryParams } from '../../helpers/routing';
import { useCurrentEntity } from '../../hooks/use-current-entity';

const PopoverContainer = styled(CarbonPopoverContainer)`
  background-color: red;
`;

const EntityContainer = styled.div`
  // Applied Negative margin to overcome the padding for the title left by the popover container
  margin-top: -${({ theme }) => theme.spacing}px;
`;

const EntityButton = styled.button<{ bgColor?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 12px;
  max-width: 290px;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
  background-color: ${({ bgColor, theme }) => bgColor || theme.palette.slateTint(95)};
  color: ${({ theme }) => theme.palette.black};
  border: 1px solid ${({ theme }) => theme.palette.black};
  border-radius: 12px;
`;

const EntityButtonSpan = styled.span`
  padding-right: 8px;
`;

const EntityList = styled.ul`
  display: block;
  padding: 0;
  margin: 0;
  max-height: 50vh;
  overflow-y: auto;
`;

const Divider = styled.li`
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  padding: 0;
  line-height: 0;
  background-color: #e5e5e5;

  &:last-child {
    display: none;
  }
`;

const unselectedEntityStyle = css`
  :focus {
    background-color: ${({ theme }) => theme.palette.slateTint(80)};
  }
  :hover {
    background-color: ${({ theme }) => theme.palette.slateTint(93)};
  }
`;

const EntityListItem = styled.li<{ selected?: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
  height: 40px;

  background-color: ${({ selected, theme }) => (selected ? theme.palette.slateTint(80) : 'transparent')};
  padding: ${({ theme }) => theme.spacing}px ${({ theme }) => theme.spacing * 2}px;

  ${({ selected }) => !selected && unselectedEntityStyle};
`;

const EntityLink = styled.a`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  text-decoration: none;
  margin-left: 12px;
  margin-right: 12px;
  font-size: 14px;
  font-family: 'Sage UI', sans-serif;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.black};
  padding: 0;

  &:hover {
    color: ${({ theme }) => theme.palette.black};
  }
`;

const toSafeBaseUrl = (pathname?: string) => {
  // Rather than dealing with the complexity of the timesheet page, we just redirect to the timesheets page.
  if (!pathname || pathname.startsWith('/timesheet/')) {
    return {
      pathname: '/timesheets',
      clearQueryParams: true,
    };
  }
  return {
    pathname,
    clearQueryParams: false,
  };
};

export function MultiEntityButton() {
  const params = useQueryParams();
  const [open, setOpen] = useState(false);
  const userEntities = useCompanies();
  const currentEntity = useCurrentEntity();
  const currentEntityName = currentEntity ? formatIntacctEntityName(currentEntity) : undefined;

  const { pathname, clearQueryParams } = toSafeBaseUrl(globalThis.location.pathname);

  return (
    <Box>
      <PopoverContainer
        p={0}
        renderCloseComponent={() => <></>}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderOpenComponent={({ isOpen, ref, ...rest }: any) => (
          <EntityButton {...rest} bgColor="#F2F5F6" data-testid="toolbar-open-entity-list">
            <EntityButtonSpan>{currentEntityName ?? 'Loading...'}</EntityButtonSpan>
            <Icon type="chevron_down" fontSize="small" color="#000" />
          </EntityButton>
        )}
      >
        <EntityContainer>
          <EntityList data-testid="toolbar-select-entity-list-item">
            {userEntities
              ? [...userEntities].sort(userEntityComparator).map((entity) => {
                  const entityName = formatIntacctEntityName(entity);
                  const newParams = clearQueryParams ? new URLSearchParams() : params;
                  newParams.set('entity', entityName);
                  return (
                    <Fragment key={entity.id}>
                      <EntityListItem selected={currentEntity?.id === entity.id} key={entity.id}>
                        <EntityLink
                          target="_blank"
                          rel="noreferrer"
                          href={`${pathname}?${newParams.toString()}`}
                          onClick={() => setOpen(false)}
                        >
                          {entityName}
                        </EntityLink>
                      </EntityListItem>
                      <Divider />
                    </Fragment>
                  );
                })
              : null}
          </EntityList>
        </EntityContainer>
      </PopoverContainer>
    </Box>
  );
}
