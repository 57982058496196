import { useLingui } from '@lingui/react/macro';
import { CACHE_TIMES, Employee, STALE_TIMES, useGetApiEmployees } from '@sit/client-shared';
import { InfiniteData } from '@tanstack/react-query';
import { Option } from 'carbon-react/lib/components/select';
import { useMemo } from 'react';
import { SelectProps } from './buildSelect';
import { useSelectControls } from './hooks/useSelectControls';
import { Root } from './shared/Root';
import { SelectField } from './shared/SelectField';
import { ValueWrapper } from './shared/ValueWrapper';

export interface EmployeeSelectProps extends Omit<SelectProps<Employee>, 'onChange'> {
  enabled?: boolean;
  companyId?: string;
  firstAsDefault?: boolean;
  filterOptions?: (options: Employee) => boolean;
  onChange: (value: Employee | undefined, quiet?: boolean) => void;
}

export const SelectEmployee = ({
  disabled,
  onChange,
  value,
  label,
  companyId,
  required,
  readOnly,
  filterOptions,
  firstAsDefault = false,
  ...props
}: EmployeeSelectProps) => {
  const { t } = useLingui();

  const { data, isLoading } = useGetApiEmployees(
    {
      queryParams: {
        companyId,
      },
    },
    { cacheTime: CACHE_TIMES.long, staleTime: STALE_TIMES.long },
  );

  const employees = useMemo<InfiniteData<Employee[]> | undefined>(() => {
    const employeeList = filterOptions ? data?.employees.filter((e) => filterOptions(e)) : data?.employees;

    return employeeList ? { pages: [employeeList], pageParams: [] } : undefined;
  }, [data, filterOptions]);

  const { innerValue, options, text, handleChange, handleOpen, handleBlur } = useSelectControls(employees, value, {
    onChange,
    useFirstAsDefault: firstAsDefault,
  });

  const entryValue = text || t`Select employee`;

  return (
    <Root>
      <ValueWrapper width={props.width} data-pendo-id={'Select.Employee'} data-testid={'Select.Employee'} disabled={disabled}>
        <SelectField
          // We don't use `readOnly` to allow for the placeholder to be shown
          disabled={disabled}
          enableVirtualScroll
          error={props.error}
          isLoading={isLoading}
          label={label}
          maxWidth={'100%'}
          onBlur={handleBlur}
          onChange={handleChange}
          onOpen={handleOpen}
          placeholder={entryValue}
          readOnly={readOnly}
          required={required}
          value={innerValue}
          virtualScrollOverscan={50}
        >
          {options.map((option, index) => (
            <Option key={`Select.Employee${option.id ?? index}`} value={option.value} text={option.text} />
          ))}
        </SelectField>
      </ValueWrapper>
    </Root>
  );
};
