import { Trans, useLingui } from '@lingui/react/macro';
import Form from 'carbon-react/lib/components/form';
import Message from 'carbon-react/lib/components/message';
import { useFormik } from 'formik';
import { ComponentProps } from 'react';
import { Link } from 'react-router';
import styled from 'styled-components';
import PublicButton from '../../components/Shared/Public/PublicButton';
import PublicForm from '../../components/Shared/Public/PublicForm';
import PublicTextBox from '../../components/Shared/Public/PublicTextBox';

const ErrorWrapper = styled.div`
  margin-top: 20px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 32px;
`;

type MessageVariant = NonNullable<ComponentProps<typeof Message>['variant']>;

export interface ForgotPasswordFormValues {
  username: string;
  company: string;
}

export interface ForgotPasswordFormProps {
  loading: boolean | undefined;
  disabled: boolean | undefined;
  initialValues?: ForgotPasswordFormValues;
  formMessage?: {
    variant: MessageVariant;
    message: string;
  };
  onSubmit: (values: ForgotPasswordFormValues) => void;
  onCancel: () => void;
  onCompanyBlur: (companyId: string) => void;
}

const INITIAL_VALUES: ForgotPasswordFormValues = {
  username: '',
  company: '',
};

const useFormValidation = () => {
  const { t } = useLingui();
  const validate = (values: ForgotPasswordFormValues) => {
    const errors: Partial<ForgotPasswordFormValues> = {};

    if (!values.username) {
      errors.username = t`Required`;
    }

    if (!values.company) {
      errors.company = t`Required`;
    }

    return errors;
  };
  return validate;
};

const ResetPasswordForm = ({
  loading,
  disabled,
  formMessage,
  onSubmit,
  onCancel,
  initialValues = INITIAL_VALUES,
  onCompanyBlur,
}: ForgotPasswordFormProps) => {
  const { t } = useLingui();
  const validate = useFormValidation();
  const { handleSubmit, values, errors, handleChange } = useFormik<ForgotPasswordFormValues>({
    initialValues,
    validate,
    isInitialValid: false,
    validateOnChange: false,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const { company, username } = values;

  return (
    <PublicForm
      title={t`Forgot your password`}
      subTitle={
        <Trans>
          <strong>Please note</strong> that this page is intended for resetting your Sage Intelligent Time password only. If you have
          forgotten your Intacct password, please reset it through the{' '}
          <Link to="https://www.intacct.com/ia/acct/resetpassword.phtml" rel="noopener noreferrer" about="_blank">
            Intacct platform
          </Link>
          <br />
          To reset your <strong>Sage Intelligent Time</strong> password, please enter the following information:
        </Trans>
      }
    >
      <Form onSubmit={handleSubmit}>
        <PublicTextBox
          id="company"
          name="company"
          disabled={disabled}
          label={t`Intacct company ID`}
          value={company}
          onChange={handleChange}
          onBlur={(ev) => onCompanyBlur(ev.currentTarget.value)}
          error={errors?.company}
        />
        <PublicTextBox
          id="username"
          name="username"
          disabled={disabled}
          label={t`Intacct user ID`}
          value={username}
          onChange={handleChange}
          error={errors?.username}
        />

        {formMessage && (
          <ErrorWrapper>
            <Message variant={formMessage.variant}>{formMessage.message}</Message>
          </ErrorWrapper>
        )}
        <Actions>
          <PublicButton buttonType="secondary" label={t`Cancel`} onClick={onCancel} disabled={disabled} />
          <PublicButton buttonType="primary" type="submit" loading={loading} label={t`Submit`} disabled={disabled} />
        </Actions>
      </Form>
    </PublicForm>
  );
};

export default ResetPasswordForm;
