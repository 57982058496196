import { useLingui } from '@lingui/react/macro';
import { useGetApiAccounts } from '@sit/client-shared';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { accountTypes, timeSourcesDefaultQueryParams } from '../constants';
import { timeSourcesRoutes } from '../helpers/routes';

interface LocalizedSourceCardsOptions {
  title: string;
  isConfigured: boolean;
  configuredMessage?: string;
  defaultMessage?: string;
}

function useMakeLocalizedSourceCards() {
  const { t } = useLingui();
  return ({
    title,
    isConfigured,
    configuredMessage = t`Connect another ${title.toLocaleLowerCase()} to auto-generate time entries.`,
    defaultMessage = t`Pull in ${title.toLocaleLowerCase()}s events to auto-generate entries.`,
  }: LocalizedSourceCardsOptions) => {
    const description = isConfigured ? configuredMessage : defaultMessage;
    const buttonLabel = isConfigured ? t`Connect another` : t`Connect`;

    return { title, description, buttonLabel, 'data-pendo-id': `connect-${title.toLocaleLowerCase()}` };
  };
}

function useSourceCards() {
  const { t } = useLingui();
  const toLocalizedCardLabels = useMakeLocalizedSourceCards();
  const { data: accounts, isLoading: isLoadingAccounts } = useGetApiAccounts(
    {},
    {
      ...timeSourcesDefaultQueryParams,
      select: (data) => {
        return data.map((e) => e.type);
      },
    },
  );

  // const { data: timeAssistantConfigured, isLoading: isLoadingDataStatus } = useGetApiDataStatus(
  //   {},
  //   { ...timeSourcesDefaultQueryParams, select: ({ albert }) => albert !== 'albert_not_yet_synced' },
  // );
  // @TODO: Hardcoded until TA launch is fixed.
  const timeAssistantConfigured = false;

  const navigate = useNavigate();

  const sourceCards = useMemo(() => {
    return accountTypes.map((type) => {
      switch (type) {
        case 'CALENDAR':
          return {
            ...toLocalizedCardLabels({
              title: t`Calendar`,
              isConfigured: accounts?.includes(type) ?? false,
              defaultMessage: t`Pull in calendar events to auto-generate entries.`,
            }),
            onClick: () => navigate(timeSourcesRoutes.calendar),
          };
        case 'EMAIL':
          return {
            ...toLocalizedCardLabels({
              title: t`Email`,
              isConfigured: accounts?.includes(type) ?? false,
              defaultMessage: t`Pull in emails to auto-generate timesheet entries.`,
            }),
            onClick: () => navigate(timeSourcesRoutes.email),
          };
        case 'TIME_ASSISTANT':
          return {
            title: t`Time Assistant`,
            description: timeAssistantConfigured
              ? t`Your Time Assistant is setup, click below to launch it.`
              : t`Install the application to capture computer activity.`,
            buttonLabel: timeAssistantConfigured ? t`Launch` : t`Download`,
            'data-pendo-id': timeAssistantConfigured ? 'launch-time-assistant' : 'download-time-assistant',
            onClick: () => (timeAssistantConfigured ? window.open('sittracker://param', '_self') : navigate('time-assistant')),
          };
      }
    });
  }, [accounts, navigate, t, toLocalizedCardLabels, timeAssistantConfigured]);

  return {
    sourceCards,
    isLoading: isLoadingAccounts, //|| isLoadingDataStatus, @TODO: Remove this when TA launch is fixed.
  };
}

export default useSourceCards;
