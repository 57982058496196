import { useUserLocale } from '@web/services/locale';

const useDocsBaseUrl = () => `https://www.intacct.com/ia/docs/${useUserLocale().locale.replaceAll('-', '_')}`;
const useHelpArticle = (article: string) => `${useDocsBaseUrl()}/help_action/Sage_Intelligent_Time/${article}`;

export const useHelpAboutUrl = () => useHelpArticle('IT-about.htm?cshid=SageIntelligentTime_About');
export const useDisconnectTimeAssistantUrl = () => useHelpArticle('Time_sources/IT-disconnect-time-assistant.htm');
export const useUninstallTimeAssistantUrl = () =>
  useHelpArticle(
    'Time_sources/IT-remove-time-assistant.htm?tocpath=Applications%7CTime%20and%20Expenses%7CIntelligent%20time%7CTime%20sources%7C_____6',
  );
