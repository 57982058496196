import { Trans, useLingui } from '@lingui/react/macro';
import { usePostEmployeeSignup } from '@sit/client-shared';
import { setAuthTokens } from '@web/redux/authentication-slice';
import { useAppDispatch } from '@web/redux/store';
import Form from 'carbon-react/lib/components/form';
import Message from 'carbon-react/lib/components/message';
import { useFormik } from 'formik';
import { Link } from 'react-router';
import styled from 'styled-components';
import PublicButton from '../../components/Shared/Public/PublicButton';
import PublicForm from '../../components/Shared/Public/PublicForm';
import PublicPassword from '../../components/Shared/Public/PublicPassword';
import PublicTextBox from '../../components/Shared/Public/PublicTextBox';

const Actions = styled.div`
  display: flex;
  gap: 20px;
`;

export interface RegisterFormValues {
  companyId: string;
  username: string;
  password: string;
  passwordConfirm: string;
}

export interface RegisterFormProps {
  inviteToken: string;
  initialValues?: RegisterFormValues;
  errorMsg?: string;
  passwordOnly?: boolean;
}

const INITIAL_VALUES: RegisterFormValues = {
  companyId: '',
  username: '',
  password: '',
  passwordConfirm: '',
};

function useFormValidation() {
  const { t } = useLingui();

  const validate = (values: RegisterFormValues) => {
    const errors: Partial<RegisterFormValues> = {};

    if (!values.companyId) {
      errors.companyId = t`Required`;
    }

    if (!values.username) {
      errors.username = t`Required`;
    }

    if (!values.password) {
      errors.password = t`Required`;
    } else if (values.password.length < 8) {
      errors.password = t`Must be at least 8 characters`;
    }

    if (!values.passwordConfirm) {
      errors.passwordConfirm = t`Required`;
    } else if (values.password !== values.passwordConfirm) {
      errors.passwordConfirm = t`Passwords don't match`;
    }

    return errors;
  };

  return validate;
}

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const InviteForm = ({ inviteToken, errorMsg, initialValues = INITIAL_VALUES, passwordOnly = false }: RegisterFormProps) => {
  const { t } = useLingui();
  const dispatch = useAppDispatch();
  const { mutate, isLoading: formSubmitting } = usePostEmployeeSignup();

  const onSubmit = ({ password }: RegisterFormValues) => {
    mutate(
      {
        body: {
          inviteToken,
          password,
          timezone: timeZone,
        },
      },
      {
        onSuccess: (data) => {
          dispatch(setAuthTokens(data));
        },
      },
    );
  };

  const validate = useFormValidation();

  const { handleSubmit, values, errors, handleChange } = useFormik<RegisterFormValues>({
    initialValues,
    validate,
    isInitialValid: false,
    validateOnChange: false,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const { password, passwordConfirm, username, companyId } = values;

  return (
    <PublicForm
      title={t`Create account`}
      subTitle={
        <Trans>
          We ve pulled your company ID and user ID from Intacct. Please create a new password specifically for Sage Intelligent Time. You’ll
          use these three credentials to login. Note, this will not change how you login to Intacct.
        </Trans>
      }
      footerComponent={
        <Actions>
          <Trans>Already have an account?</Trans>
          <Link to="/login">
            <Trans>Log in</Trans>
          </Link>
        </Actions>
      }
    >
      <Form
        saveButton={<PublicButton buttonType="primary" key="register" type="submit" label={t`Create account`} />}
        onSubmit={handleSubmit}
      >
        <PublicTextBox
          readOnly={passwordOnly}
          label={t`Company ID`}
          labelInline={false}
          labelAlign="left"
          name="companyId"
          value={companyId}
          onChange={handleChange}
          error={errors.companyId}
        />
        <PublicTextBox
          readOnly={passwordOnly}
          label={t`User ID`}
          labelInline={false}
          labelAlign="left"
          name="username"
          value={username}
          onChange={handleChange}
          error={errors.username}
        />
        <PublicPassword
          label={t`Password`}
          forceObscurity={formSubmitting}
          labelInline={false}
          labelAlign="left"
          name="password"
          onChange={handleChange}
          value={password}
          error={errors.password}
        />
        <PublicPassword
          label={t`Confirm password`}
          forceObscurity={formSubmitting}
          labelInline={false}
          labelAlign="left"
          name="passwordConfirm"
          onChange={handleChange}
          value={passwordConfirm}
          error={errors.passwordConfirm}
        />
        {errorMsg && (
          <div className="error-wrapper">
            <Message variant="error">{errorMsg}</Message>
          </div>
        )}
      </Form>
    </PublicForm>
  );
};

export default InviteForm;
