import { Trans, useLingui } from '@lingui/react/macro';
import { loadingChunkRetry, useCanEditStaffTimesheets, useHasStaffTimesheets } from '@sit/client-shared';
import ErrorBoundary from '@web/components/ErrorBoundary';
import { PageContent, PageHeader } from '@web/components/Page';
import Button from '@web/components/Shared/Button';
import DashboardContainer from '@web/containers/DashboardContainer';
import { NotFoundMessage } from '@web/containers/NotFoundPage/NotFoundMessage';
import { newRateCardRoute } from '@web/containers/RateCards/helpers/routes';
import NewRateCard from '@web/containers/RateCards/NewRateCard';
import { ErrorComponent } from '@web/containers/TimeApprovals/Error';
import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, Route, Routes, useNavigate } from 'react-router';
import styled from 'styled-components';

const RateCardsPage = lazy(() => loadingChunkRetry(() => import('@web/containers/RateCards')));
const RateCardsDetailsPage = lazy(() => loadingChunkRetry(() => import('@web/containers/RateCards/RateCardDetails')));

const Content = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 58px);
  flex-direction: column;
  div[data-component='tabs'] {
    width: 100%;
  }
`;

function RateCardsIndexLayout() {
  const { t } = useLingui();
  const navigate = useNavigate();
  const canEdit = useCanEditStaffTimesheets();

  return (
    <DashboardContainer showSecondaryNav>
      <Helmet>
        <title>{t`Rate cards | Sage Intelligent Time`}</title>
      </Helmet>

      <PageContent>
        <PageHeader data-testid="rate-cards-title" title={t`Rate cards`}>
          {canEdit && (
            <Button
              buttonType={{
                default: 'primary',
                embedded: 'secondary',
              }}
              onClick={() => {
                navigate(newRateCardRoute);
              }}
            >
              <Trans>Create rate card</Trans>
            </Button>
          )}
        </PageHeader>
        <ErrorBoundary fallbackUI={(error) => <ErrorComponent error={error} />}>
          <Suspense fallback={null}>
            <Content>
              <Outlet />
            </Content>
          </Suspense>
        </ErrorBoundary>
      </PageContent>
    </DashboardContainer>
  );
}

function RateCardsIndex() {
  const canEdit = useCanEditStaffTimesheets();
  const { data: isStaff, isLoading } = useHasStaffTimesheets();

  if (isLoading) {
    return (
      <Routes>
        <Route index element={<RateCardsIndexLayout />} />
      </Routes>
    );
  }

  if (!isStaff) {
    return (
      <Routes>
        <Route path="*" element={<NotFoundMessage />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route element={<RateCardsIndexLayout />}>
        <Route index element={<RateCardsPage />} />
        {canEdit && <Route path="new" element={<RateCardsPage activeModal={<NewRateCard />} />} />}
        <Route path=":rateCard">
          <Route index path="*" element={<RateCardsPage activeModal={<RateCardsDetailsPage />} />} />
        </Route>
      </Route>

      <Route path="*" element={<NotFoundMessage />} />
    </Routes>
  );
}

export default RateCardsIndex;
