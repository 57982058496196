import { addMinutes, format } from 'date-fns';
import moment from 'moment';

/* 15 => 3 PM */
const intToTime = (int: number) => {
  if (int === 0) return '12 AM';
  if (int < 12) return `${int} AM`;
  if (int === 12) return `${int} PM`;
  if (int === 24) return '11:59 PM';
  return `${int - 12} PM`;
};

const halfHours = Array.from({ length: 48 }, (_, i) => format(addMinutes(new Date(0, 0, 0, 0, 0), i * 30), 'HH:mm')) as readonly string[];

const secondsToHours = (seconds: number | string) => {
  if (typeof seconds === 'string') {
    return Number.parseInt(seconds, 10) / 3600;
  }
  return seconds / 3600;
};

const secondsToHoursFixed = (seconds = 0) => secondsToHours(seconds).toFixed(2);

const getTimesheetDays = (timesheetStart: moment.MomentInput, timesheetEnd: moment.MomentInput) => {
  const start = moment(timesheetStart);
  const end = moment(timesheetEnd);
  const daysDiff = end.diff(start, 'days');
  const days = [start];
  for (let i = 1; i <= daysDiff; i++) {
    days.push(start.clone().add(i, 'days'));
  }
  return days;
};

function formatLabelNumber(num = 0) {
  return `${num < 0 ? '-' : ''}${Math.abs(num).toString().padStart(2, '0')}`;
}

export { formatLabelNumber, getTimesheetDays, halfHours, intToTime, secondsToHours, secondsToHoursFixed };
